import * as React from "react";
import { Box, CssBaseline, } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { YC_FOOTER_COPYRIGHT } from "utils/strings";
import HomepageFooter from "components/homepage-footer/homepage-footer";
import DashboardPageHeader from "components/dashboard-header/dashboard-header";
import SideNav from "components/reusable-sideNav/SideNav";
import DasboardAppsTabs from "../dasheboard-new/dashboard-apps-tab";
import { BLACK } from "utils/colors";
import ViewMoreBillingDetails from "./view-more-details";
const UseStyles = makeStyles(() => ({
  loginContainBlock: {
    flex: 1.6,
    "@media (max-width: 100px)": {
      display: "none",
    },
  },
}));
const drawerWidth = 100;
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}
function BillingMoreDetailsDashboard(props: Props) {
  const { loginContainBlock } = UseStyles();
  return (
    <div style={{ height: "100%", flex: 1 }}>
      <Box
        sx={{
          marginBottom: "40px",
          height: "100%",
          flex: 1,
        }}
      >
        <CssBaseline />
        <DashboardPageHeader />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <SideNav />
          <Box
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              marginTop: 5,//8,
              marginLeft: 1,
            }}
          >
            <Box
                className="mx-3 me-lg-4"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    // mt: 7,
                    ml: 5,
                }}
            >
            {/* <ApplicationPlanPricing /> */}
            <ViewMoreBillingDetails/>
            <hr
              style={{
                  color: BLACK,
                  backgroundColor: BLACK,
                  width: "100%",
                  marginTop: 10,
                  // marginLeft: "2.5%",
                  height: 2
              }}
            />
            {/* <AppUserSettings/> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <HomepageFooter isLinkFooter={true} displayText={YC_FOOTER_COPYRIGHT} />
    </div>
  );
}
export default BillingMoreDetailsDashboard;