import * as React from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Button, Link, SvgIcon, Tab, Tabs, Typography } from '@mui/material';
import { BLACK, BLACK_2, BLUE, GREY, WHITE } from 'utils/colors';
import { useTranslation } from 'react-i18next';
import { SUBHEADERBOLD } from 'utils/fonts';
import Balance from './balance';
import BillingTable from './latest-billing';
import BillingHistory from './billing-history';
import BillingInformation from './billing-information';
import BillingSettings from './billing-setting';
import BillingSettingsSecondStep from './billing-setting-secondStep';
const useStyles = makeStyles(() => ({
  appText: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "23.3397px",
    lineHeight: "50px",
    color: "#FFFFFF",
    textAlign: "start",
  },
  inputBox: {
    float: "right",
    marginRight: "6px",
    marginLeft: "auto",
  }
}));
export default function BillingComponents() {
  const [value, setValue] = React.useState(0);
  const { appText, inputBox } = useStyles();
  const { t } = useTranslation()
  const [isLatestClicked, setIsLatestClicked] = React.useState(true)
  const [isBillingHistoryClicked, setIsBillingHistoryClicked] = React.useState(false)
  const [isBillingInformationClicked, setIsBillingInformationClicked] = React.useState(false)
  const [isBalanceClicked, setIsBalanceClicked] = React.useState(false)
  const [isAdvancedSettingClicked, setIsAdvancedSettingClicked] = React.useState(false)
  const [isCreditCardButtonClicked, setIsCreditCardButtonClicked] = React.useState(false)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const appDetail = `${t("YC_DASHBOARD_APP_DETAILS")}`
  return (
    <Box>
     <Typography sx={{mt: 1, width: "100%", color: BLACK, fontStyle: SUBHEADERBOLD, textAlign: "start" }}>
          {t("YC_BILLING")}
        </Typography>
      <Box sx={{ border: "1px solid #CCD0D4", mt: 1.5, borderColor: GREY, boxShadow: 2}}>
        <Box sx={{width: "95%"}} >
          <Box sx={{width: "100%"}}>
            <Box sx={{display: "flex", justifyContent: "flex-start", width: "100%"}}>
                <Button sx={{
                     marginLeft: "20px", //{xs: "-10%", sm: "-1%", lg: "-26%", xl: "-45%"},
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isLatestClicked ? BLACK :  WHITE, 
                    color: isLatestClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }}
                onClick={() => {
                        setIsLatestClicked(true)
                        setIsBillingHistoryClicked(false)
                        setIsBillingInformationClicked(false)
                        setIsBalanceClicked(false)
                        setIsAdvancedSettingClicked(false)
                        setIsCreditCardButtonClicked(false)
                  }}>{t("YC_LATEST")}</Button>
                <Button sx={{
                     marginLeft: "10px",
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isBillingHistoryClicked ? BLACK :  WHITE, 
                    color: isBillingHistoryClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }} onClick={() => {
                    setIsLatestClicked(false)
                    setIsBillingHistoryClicked(true)
                    setIsBillingInformationClicked(false)
                    setIsBalanceClicked(false)
                    setIsAdvancedSettingClicked(false)
                    setIsCreditCardButtonClicked(false)
                  }}>{t("YC_BILLING_HISTORY")}</Button>
                <Button sx={{
                     marginLeft: "10px",
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isBillingInformationClicked ? BLACK :  WHITE, 
                    color: isBillingInformationClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }} onClick={() => {
                    setIsLatestClicked(false)
                    setIsBillingHistoryClicked(false)
                    setIsBillingInformationClicked(true)
                    setIsBalanceClicked(false)
                    setIsAdvancedSettingClicked(false)
                    setIsCreditCardButtonClicked(false)
                  }}>{t("YC_BILLING_INFORMATION")}</Button>
                <Button sx={{
                     marginLeft: "10px",
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isBalanceClicked ? BLACK :  WHITE, 
                    color: isBalanceClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }} onClick={() => {
                    setIsLatestClicked(false)
                    setIsBillingHistoryClicked(false)
                    setIsBillingInformationClicked(false)
                    setIsBalanceClicked(true)
                    setIsAdvancedSettingClicked(false)
                    setIsCreditCardButtonClicked(false)
                  }}>{t("YC_BALANCE")}</Button>
                  <Button sx={{
                     marginLeft: "10px",
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isAdvancedSettingClicked ? BLACK :  WHITE, 
                    color: isAdvancedSettingClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }} onClick={() => {
                    setIsLatestClicked(false)
                    setIsBillingHistoryClicked(false)
                    setIsBillingInformationClicked(false)
                    setIsBalanceClicked(false)
                    setIsAdvancedSettingClicked(true)
                    setIsCreditCardButtonClicked(false)
                  }}>{t("YC_ADVANCED_SETTINGS")}</Button>
            </Box>
            <hr
                style={{
                    color: BLACK,
                    backgroundColor: BLACK_2,
                    width: "100%",
                    marginTop: "-2px",
                    marginLeft: "10px",
                    height: 2
                }}
            />
          </Box>
        </Box>
        {isLatestClicked === true && (<BillingTable/>)}
        {isBillingHistoryClicked === true && (<BillingHistory/>)}
        {isBillingInformationClicked === true && (<BillingInformation/>)}
        {isBalanceClicked === true && (<Balance/>)}
        {(isAdvancedSettingClicked === true && isCreditCardButtonClicked === false) && (<BillingSettings setIsCreditCardButtonClicked={setIsCreditCardButtonClicked}/>)}
        {(isAdvancedSettingClicked === true && isCreditCardButtonClicked === true) && (<BillingSettingsSecondStep setIsCreditCardButtonClicked={setIsCreditCardButtonClicked}/>)}
      </Box>
    </Box>
  );
}