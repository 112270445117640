import * as React from "react";
import { Box, CssBaseline, ListItemIcon, Tooltip, Typography, } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardPageHeader from "components/dashboard-header/dashboard-header";
import { SUBHEADER1, SUBHEADERBOLD } from "utils/fonts";
import { BLACK, BLUE, GREY, WHITE, YELLOW } from "utils/colors";
import CustomButton from "components/custom-button/custom-button";
import { useTranslation } from "react-i18next";
import SupportIcon from "../../../assets/helpIcon.png";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';

const UseStyles = makeStyles(() => ({
    loginContainBlock: {
        flex: 1.6,
        "@media (max-width: 100px)": {
            display: "none",
        },
    },
}));

const drawerWidth = 250;

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

function ApplicationActivateSetup(props: any) {

    const { loginContainBlock } = UseStyles();
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [planData, setPlanData] = React.useState(props.planData)
    // const crypto = require("crypto");
    // const crypto = require("crypto-browserify");
    // const stream =  require.resolve("stream-browserify")
    // const redirectToPaymentGateway = (paymentDetails: any) => {
    //     const gatewayUrl = "https://payment.mytmoney.com/checkout"; // Replace with actual URL
    //     const params = new URLSearchParams({
    //       amount: paymentDetails.amount,
    //       currency: "MUR", // Adjust currency as needed
    //       transactionId: paymentDetails.transactionId,
    //       callbackUrl: "https://your-app.com/payment-success", // Your callback URL
    //       apiKey: process.env.REACT_APP_MY_T_MONEY_API_KEY, // Add additional params if required
    //     });
      
    //     window.location.href = `${gatewayUrl}?${params.toString()}`;
    //   };
    
    const generateTransactionId = () => uuidv4()

      const redirectToPaymentGateway = (paymentDetails: any) => {
        const gatewayUrl =  "https://pay.mytmoney.mu/Mt/web/payments" // "https://payment.mytmoney.com/checkout"; // Replace with actual gateway URL
        const [currency, amount] = paymentDetails.amount.split(" ");
        const signature = generateSignature({
            amount: amount,
            currency: currency,
            transactionId: paymentDetails.transactionId,
            callbackUrl: "https://your-app.com/payment-success",
          });
        
        const merchantTradeNo = generateTransactionId()// `TRADE_${new Date().getTime()}`; // Example: TRADE_1636575068589

        // Ensure all parameters are strings
        const params = new URLSearchParams({
          amount:"2000", //String(paymentDetails.amount), // Convert to string
          currency: "MUR",
          transactionId: String(paymentDetails.transactionId), // Convert to string
          callbackUrl: "https://yourwebsite.com/payment/success",// "https://y-click.io/payment-success", // Your callback URL
          paymentType: "credit_card",
          signature: signature,
          appId: "1000003657",
          merchantTradeNo: merchantTradeNo,
        //   apiKey: //" process.env.REACT_APP_MY_T_MONEY_API_KEY || "", // Use empty string if undefined
        });
        // Redirect to the payment gateway
        // window.location.href = `${gatewayUrl}?${params.toString()}`;
        const newTab = window.open(`${gatewayUrl}?${params.toString()}`, '_blank'); // Open a new blank tab

      };

    //   const redirectToPaymentGateway2 = (paymentDetails: any) => {
    //     const amount = 2000;
    //     const currency = "MUR";
    //     const transactionId = "39ee533e-6691-42c9-b72b-8456feb3108a";
    //     const callbackUrl = "https://y-click.io/payment-success";
    //     const paymentType = "credit_card";
    //     const appId = "1000003657";
    //     const merchantTradeNo = `TRADE-${Date.now()}`;
    //     const signature = "b39b31f1096f9827165be26bea12dc02d085cbc4ddb12500bf73987b3afceb72"; // Example signature
      
    //     const paymentUrl = `https://pay.mytmoney.mu/Mt/web/payments?amount=${amount}&currency=${currency}&transactionId=${transactionId}&callbackUrl=${encodeURIComponent(
    //       callbackUrl
    //     )}&paymentType=${paymentType}&signature=${signature}&appId=${appId}&merchantTradeNo=${merchantTradeNo}`;
      
    //     window.location.href = paymentUrl;
    //   };
      

    //   const redirectToPaymentGateway3 = (paymentDetails: any) => {
    //     const merchantId = 1; // Replace with your Merchant ID
    //     const merchantTradeNo = `TRADE-${Date.now()}`; // Generate a unique trade number
    //     const amount = 100; // Replace with the actual amount
    //     const currency = "MUR"; // Replace with the required currency
    //     const returnUrl = "http://localhost:3001/payment/success";
    //     const cancelUrl = "http://localhost:3001/payment/cancel";
      
    //     // Construct the redirection URL
    //     const paymentUrl = `"https://pay.mytmoney.mu/Mt/web/payments"?merchantId=${merchantId}&merchantTradeNo=${merchantTradeNo}&amount=${amount}&currency=${currency}&returnUrl=${encodeURIComponent(
    //       returnUrl
    //     )}&cancelUrl=${encodeURIComponent(cancelUrl)}`;
      
    //     // Redirect the user
    //     window.location.href = paymentUrl;
    //   };

    //   const redirectToPaymentGateway4 = (paymentDetails: any) => {
    //     const merchantId = "1";
    //     const merchantTradeNo = `TRADE-${Date.now()}`;
    //     const amount = 100;
    //     const currency = "MUR";
    //     const returnUrl = "https://yourwebsite.com/payment/success";
    //     const cancelUrl = "https://yourwebsite.com/payment/cancel";
      
    //     const form = document.createElement("form");
    //     form.method = "POST";
    //     form.action = "https://pay.mytmoney.mu/Mt/web/payments";
      
    //     const addHiddenField = (name: any, value: any) => {
    //       const field = document.createElement("input");
    //       field.type = "hidden";
    //       field.name = name;
    //       field.value = value;
    //       form.appendChild(field);
    //     };
      
    //     addHiddenField("merchantId", merchantId);
    //     addHiddenField("merchantTradeNo", merchantTradeNo);
    //     addHiddenField("amount", amount);
    //     addHiddenField("currency", currency);
    //     addHiddenField("returnUrl", returnUrl);
    //     addHiddenField("cancelUrl", cancelUrl);
    //     addHiddenField("paymentType", "credit_card");
      
    //     document.body.appendChild(form);
    //     form.submit();
    //   };
      
    //   const redirectToPaymentGateway5 = async (paymentDetails: any) => {
    //     const merchantTradeNo = generateTransactionId(); // Your unique identifier logic
    //     if (!merchantTradeNo) {
    //       console.error("Merchant Trade No is not generated!");
    //       return;
    //     }
      
    //     const paymentPayload = {
    //       merchantTradeNo, // Include the required field
    //       amount: 100, // Example amount
    //       currency: "MMK",
    //       returnUrl: "https://yourwebsite.com/payment/success",
    //       cancelUrl: "https://yourwebsite.com/payment/cancel",
    //     };
      
    //     try {
    //       const response = await fetch("https://api.mytmoney.com/initiate-payment", {
    //         method: "POST",
    //         headers: { "Content-Type": "application/json" },
    //         body: JSON.stringify(paymentPayload),
    //       });
      
    //       const data = await response.json();
      
    //       if (data.redirectUrl) {
    //         window.location.href = data.redirectUrl; // Redirect to payment gateway
    //       } else {
    //         console.error("Redirection URL is missing from the response.");
    //       }
    //     } catch (error) {
    //       console.error("Error initiating payment:", error);
    //     }
    //   };
      
      
      
const generateSignature = (params: any) => {
  const secretKey = "704CV2x1aZevGwrA+e4yB+9OAldsdWmXrxsKwPnuMgfvTgJXbHVpl68bCsD57jIHceagNAQRMiQyvF1KvOoZlA==" //process.env.MY_T_SECRET_KEY; // Your secret key

  // Concatenate the parameters in the required order
  const stringToSign = `${params.amount}${params.currency}${params.transactionId}${params.callbackUrl}`;

  // Generate HMAC-SHA256 signature
//   const signature = CryptoJS.createHmac("sha256", secretKey).update(stringToSign).digest("hex");
     const signature = CryptoJS.HmacSHA256(stringToSign, secretKey).toString(CryptoJS.enc.Hex);

  return signature;
};

// Example usage
// const signature = generateSignature({
//   amount: "1000",
//   currency: "MUR",
//   transactionId: "TX123456",
//   callbackUrl: "https://your-app.com/payment-success",
// });

// console.log("Generated Signature:", signature);


      const handlePayment = (amount: string) => {
        const transactionId = generateTransactionId();
        console.log("transactionId---> ", transactionId)
        const paymentDetails = {
          amount: amount, // Example amount
          transactionId: transactionId, // Example transaction ID
        };
              
        redirectToPaymentGateway(paymentDetails);
      };
      
    return (
        <div style={{ height: "100%", flex: 1 }}>
            <Box
                sx={{
                    marginBottom: "40px",
                    height: "100%",
                    flex: 1,
                }}
            >
                <CssBaseline />
                <DashboardPageHeader />

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Typography style={SUBHEADERBOLD}>
                            Mauritius Yellow Pages
                        </Typography>
                        <Typography>
                            Activation And Setup
                        </Typography>
                        <Box sx={{width: "100%",display: "flex", justifyContent: "flex-start"}}>
                            <CustomButton
                                placeHolder={ t("YC_BACK")}
                                textTransform="none"
                                background={BLUE}
                                height="30px"
                                alignItems="center"
                                marginLeft="30px"
                                marginTop="10px"
                                width= "10%"
                                borderRadius="4px"
                                titl
                                border={BLACK}
                                onClick={() => {navigate(-1)}}
                                buttonStyle={{
                                    SUBHEADER1
                                }}
                            />
                        </Box>
                        <hr
                            style={{
                                color: BLACK,
                                backgroundColor: BLACK,
                                width: "95%",
                                marginTop: 10,
                                marginLeft: "2.5%",
                                height: 2
                            }}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", sm: "row", lg: "row", xl: "row" },
                                width: "100%",
                                height: "40%",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "30px",
                                    height: {xs: "70", sm: "60%", lg: "50%", xl: "50%"},
                                    width: "95%", //{xs: "100", sm: "30%", lg: "30%", xl: "30%"},
                                    border: 2,
                                    borderColor: GREY,
                                    boxShadow: 2,
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    marginLeft: "2.5%"
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: { xs: "column", sm: "row", lg: "row", xl: "row" },
                                        width: "100%",
                                        marginTop: "10px",
                                        height: "100%",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "10px",
                                            height: "100%",
                                            width: { xs: "100%", sm: "100%", lg: "30%", xl: "30%" },
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start"
                                        }}
                                    >

                                        <Typography style={{ marginTop: "10px", marginLeft: "10px", fontWeight: 700 }}>
                                            {"Yellow Pages Plan:"}
                                        </Typography>
                                        <Typography style={{ marginTop: "10px", marginLeft: "20px" }}>
                                            {`${planData.plan + " " + t("YC_BUSINESS_PAGE")}`}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ marginTop: "15px", height: { xs: "0px", sm: "30px", lg: "30px", xl: "30px" }, width: "3px", background: BLACK }} />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "10px",
                                            height: "100%",
                                            width: { xs: "100%", sm: "100%", lg: "30%", xl: "30%" },
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                        }}
                                    >

                                        <Typography style={{ marginTop: "10px", marginLeft: "10px", fontWeight: 700 }}>
                                            {"Subscription Fee:"}
                                        </Typography>
                                        <Typography style={{ marginTop: "10px", marginLeft: "20px" }}>
                                            {planData.prize}
                                        </Typography>
                                    </Box>
                                    <CustomButton
                                        placeHolder={planData.plan === "Classic" ? t("YC_Setup") : t("YC_PAY_NOW")}
                                        textTransform="none"
                                        background={BLUE}
                                        marginTop="10px"
                                        height="40px"
                                        marginLeft="10px"
                                        marginRight="10px"
                                        alignItems="center"
                                        width="20%"
                                        borderRadius="4px"
                                        border={BLACK}
                                        titleColor={WHITE}
                                        hoverColor={YELLOW}
                                        onClick={() => { handlePayment(planData.prize)
                                            // navigate('/application-basic-setup', { state: { planData } })
                                        }}
                                        buttonStyle={{
                                            SUBHEADER1
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <CustomButton
                                        placeHolder={t("YC_CHOOSE_OTHER_PLANS")}
                                        textTransform="none"
                                        background={BLACK}
                                        height="40px"
                                        marginRight="10px"
                                        alignItems="center"
                                        width="40%"
                                        borderRadius="4px"
                                        border={BLACK}
                                        titleColor={YELLOW}
                                        hoverColor={WHITE}
                                        onClick={() => { }}
                                        buttonStyle={{
                                            SUBHEADER1
                                        }}
                                />
                        <hr
                            style={{
                                color: BLACK,
                                backgroundColor: BLACK,
                                width: "95%",
                                marginTop: 50,
                                marginLeft: "2.5%",
                                height: 2
                            }}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "90%",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginLeft: "5%"
                            }}
                        >
                            <Typography style={{ marginTop: "15px", fontSize: "16px" }}>
                                {t("YC_BUSINESS_DIRECTORY_POWERED_BY")}
                            </Typography>
                            <Typography style={{ marginTop: "15px", fontSize: "12px" }}>
                                {`MYP (Mauritius Yellow Pages) are a support local businesses driven company...............`}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: { xs: "100", sm: "60%", lg: "60%", xl: "60%" },
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    marginBottom: 5
                                }}
                            >
                                <CustomButton
                                    placeHolder={t("YC_CONTACT_SALES")}
                                    textTransform="none"
                                    background={BLUE}
                                    height="35px"
                                    alignItems="center"
                                    marginRight="10px"
                                    marginTop="10px"
                                    width="25%"
                                    borderRadius="4px"
                                    titl
                                    border={BLACK}
                                    onClick={() => { }}
                                    buttonStyle={{
                                        SUBHEADER1
                                    }}
                                />
                                <CustomButton
                                    placeHolder={t("YC_LEARN_MORE")}
                                    textTransform="none"
                                    background={"transparent"}
                                    height="35px"
                                    alignItems="center"
                                    marginRight="10px"
                                    marginTop="10px"
                                    width="25%"
                                    borderRadius="4px"
                                    titleColor={BLACK}
                                    border={BLACK}
                                    onClick={() => { }}
                                    buttonStyle={{
                                        SUBHEADER1
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* <HomepageFooter isLinkFooter={true} displayText={YC_FOOTER_COPYRIGHT} /> */}
        </div>
    );
}

export default ApplicationActivateSetup;
