import { Avatar, Box, Divider, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import CheckBox from "components/check-box/check-box";
import CustomButton from "components/custom-button/custom-button";
import CustomSwitch from "components/switch-button/switch-button";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HomeApi from "services/HomeAPi";
import { BLACK, BLACK_2, BLACK_3, BLUE, GREEN, GREY, GREY_3, GREY_4, PINK, RED, WHITE, YELLOW } from "utils/colors";
import { HEADER2, SUBHEADER1, SUBHEADER2, SUBHEADERBOLD, SUBHEADERBOLD2 } from "utils/fonts";
import { validateEmail } from "utils/helper";
import Notification from "views/Notifications/Notification";

const EditUserDialogStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "90%",
        overflowY: 'auto',
        OverflowX: "none",
        maxWidth: 700,
        minWidth: 450,
        background: "white",
    },
    dividerStyleVerticle: {
        borderColor: GREY,
        borderWidth: 0.5,
        opacity: 1,
    },
    otpViewTitle: {
        fontSize: 16,
        fontWeight: "500",
        color: "#1F2328",
        lineHeight: "24px",
    },
    titleText: {
        display: "flex",
        marginTop: 20,
        marginBottom: 20,
        justifyContent: "center",
        alignItems: "center",
    },
    subTitle: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "28px",
        textAlign: "left",
        color: BLACK,
    }

}))
function Bank(props: any) {
    const { t } = useTranslation()
    const [subItemDataId, setSubItemDataId] = useState([])
    const [inputData, setInputData] = useState({
        title: "",
        bankName: "",
        optionTransaction: "",
        accountNumber: "",
        swiftCode: "",
        ibanCode: "",
        branchName: "",
        address: ""
    })

    const [checkedItems, setCheckedItems] = useState([{
        checkBoxName: t("YC_CASH"),
        labelColor: BLUE,
        state: false
    },
    {
        checkBoxName: t("YC_TRANSFER"),
        labelColor: PINK,
        state: false
    },
    {
        checkBoxName: t("YC_MOBILE_PAY"),
        labelColor: GREEN,
        state: false
    },
    {
        checkBoxName: t("YC_CREDIT_CARD"),
        labelColor: "",
        state: false
    }]);
    

    const getUserId = () => {
        let userId
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            userId = details.id
        }
        return userId
    }

    const getAccountDetails = async () => {
        const response = await HomeApi.getAccountDetails(getUserId())
        if (response.status === true) {
          const data = response.data[0]
          setInputData({
            title: data.title,
            bankName: data.bank_name,
            optionTransaction: data.option,
            accountNumber: data.bank_account,
            swiftCode: data.swift_code,
            ibanCode: data.iban_code,
            branchName: data.branch_name,
            address: data.bank_address
        })
        setCheckedItems((preyFields: any) =>
            preyFields.map((fields: any, i: any) =>
                fields.checkBoxName === data.option ? { ...fields, state: !fields.state } : { ...fields, state: false }
          )
        );
        }
    }

    const postAccountDetails = async() => {
       const payload = {
          title: inputData.title,
          option: inputData.optionTransaction,
          bank_account : inputData.accountNumber,
          swift_code : inputData.swiftCode,
          iban_code : inputData.ibanCode,
          bank_name : inputData.bankName,
          branch_name : inputData.branchName,
          bank_address : inputData.address
       }
       const response = await HomeApi.postAccountDetails(payload, getUserId())
       if (response.status === true) {
          Notification.notifySuccess(response.message)
       } else {
            Notification.notifyError(response.message)
       }

    }

    useEffect(() => {
        getAccountDetails()
    },[])

    const renderTopButtonsView = () => {
        return(
            <>
               <Box sx={{ mt: 2, mb: 4, width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                    <CustomButton
                        placeHolder={t("YC_ADD_NEW_ACCOUNT")}
                        textTransform="none"
                        background={YELLOW}
                        height="35px"
                        width={"180px"}
                        buttonStyle={{
                            HEADER2,
                            color: BLACK,
                            "&:hover" : {
                                background: BLACK,
                                color: YELLOW
                            }
                        }}
                        onClick={() => { postAccountDetails() }}
                    />
                    <CustomButton
                        placeHolder={t("YC_GO_BACK_MANAGER_ACCOUNT")}
                        textTransform="none"
                        background={BLACK}
                        height="35px"
                        width={"100"}
                        marginLeft="10px"
                        marginRight="50px"
                        borderRadius="7.2px"
                        buttonStyle={{
                            HEADER2,
                            color: YELLOW
                        }}
                        onClick={() => { }}
                    />
                </Box>
            </>
        )
    }

    const renderBottonButtonsView = () => {
        return(
            <>
               <Box sx={{mt: 3, mb: 4, marginLeft: "18%", width: "70%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                    <CustomButton
                        placeHolder={t("YC_SAVE")}
                        textTransform="none"
                        background={BLUE}
                        height="35px"
                        width={"100px"}
                        borderRadius="7.2px"
                        buttonStyle={{
                            HEADER2
                        }}
                        onClick={() => { postAccountDetails() }}
                    />
                    <CustomButton
                        placeHolder={t("YC_CANCEL")}
                        textTransform="none"
                        background={grey}
                        height="35px"
                        width={"100px"}
                        marginLeft="10px"
                        marginRight="50px"
                        borderRadius="7.2px"
                        buttonStyle={{
                            HEADER2
                        }}
                        onClick={() => { }}
                    />
                </Box>
            </>
        )
    }

    const textFieldView = (key: string, value: string) => {
        return (
            <Box sx={{boxShadow: 2, 
            width: {xs: "90%",
                sm: "90%", 
                lg: (key === "title" || key === "accountNumber" || key === "address") ? "90%" : "70%", 
                xl: (key === "title" || key === "accountNumber" || key === "address") ? "90%" : "70%"}}}>
                <TextField style={{
                    width: "100%",
                }} label={""} variant="outlined"
                    size="small"
                    value={value}
                    onChange={(text) => {
                        const trimStr = text.target.value.trimStart();
                        if (key === "title") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                title: trimStr,
                            }));
                        } else if (key === "bankName") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                bankName: trimStr,
                            }));
                        } else if (key === "accountNumber") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                accountNumber: trimStr,
                            }));
                        } else if (key === "swiftCode") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                swiftCode: trimStr,
                            }));
                        } else if (key === "ibanCode") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                ibanCode: trimStr,
                            }));
                        } else if (key === "branchName") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                branchName: trimStr,
                            }));
                        } else if (key === "address") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                address: trimStr,
                            }));
                        }
                    }}
                />
            </Box>
        )
    }

    const renderLabelAndTextView = (key: string, label:string, value: string, isRight = false) => {
        return(
            <Box sx={{mt: 2, width: "100%", display: "flex", flexDirection: {xs: "column", sm: "column", lg: "row", xl: "row"}}}>
                <Typography sx={{ 
                    width: {xs: "60%",
                         sm: "60%", 
                         lg: (key === "title" || key === "accountNumber" || key === "address") ? "20%" : "40%", 
                         xl: (key === "title" || key === "accountNumber" || key === "address") ? "20%" : "40%"}, 
                    textAlign: {xs: "start", sm: "start", lg: "end", xl: "end"},
                    paddingRight: 5 }}>
                    {label}
                </Typography>
                {textFieldView(key, value)}
            </Box>
        )
    }

    const handleClickOfCheckbox = (index: any, checkBoxName : string) => {
        setCheckedItems((preyFields: any) =>
            preyFields.map((fields: any, i: any) =>
              i === index ? { ...fields, state: !fields.state } : { ...fields, state: false }
          )
        );
        setInputData((prevFields) => ({
            ...prevFields,
            optionTransaction: checkBoxName,
        }));
      }

    // const checkBoxView = (key: string, labelColor: string, label: string) => {
    //     return(
    //         <>
    //           <CheckBox
    //                 sx={{ color: grey, marginLeft: "10px", "&.Mui-checked": { color: grey } }}
    //                 value="top"
    //                 labelColor={labelColor}
    //                 label={label}
    //                 onChange={(value: any) => {
    //                     checkedItems.map((items: any) => {
    //                         if(items.checkBoxName === label) {
    //                             setCheckedItems((prevFields) => ({
    //                                 ...prevFields,
    //                                 state: true
    //                             }));
    //                         }
    //                     })
    //                     if(key === t("YC_CASH")) {
    //                         setInputData((prevFields) => ({
    //                             ...prevFields,
    //                             optionTransaction: value.target.checked === true ? t("YC_CASH") : "",
    //                         }));
    //                     } else if(key === t("YC_TRANSFER")) {
    //                         setInputData((prevFields) => ({
    //                             ...prevFields,
    //                             optionTransaction: value.target.checked === true ? t("YC_TRANSFER") : "",
    //                         }));
    //                     } else if(key === t("YC_MOBILE_PAY")) {
    //                         setInputData((prevFields) => ({
    //                             ...prevFields,
    //                             optionTransaction: value.target.checked === true ? t("YC_MOBILE_PAY") : "",
    //                         }));
    //                     } else if(key === t("YC_CREDIT_CARD")) {
    //                         setInputData((prevFields) => ({
    //                             ...prevFields,
    //                             optionTransaction: value.target.checked === true ? t("YC_CREDIT_CARD") : "",
    //                         }));
    //                     }
    //                 }}
    //             />
    //         </>
    //     )
    // }

    const renderLabelAndCheckboxView = () => {
        return(
            <Box sx={{mt: 2, width: "100%", display: "flex", flexDirection: "row"}}>
                <Typography sx={{width: "20%", 
                    textAlign: "end", paddingRight: 5}}>
                    {t("YC_OPTIONS")}
                </Typography>
                <Box sx={{ marginLeft: "10px", width: "100%", display: "flex", justifyContent: "flex-start" }}>
                    {checkedItems.map((item: any, index: any) => {
                        return(
                            <>
                              <CheckBox
                                sx={{ color: grey, marginLeft: "10px", "&.Mui-checked": { color: grey } }}
                                value="top"
                                checked={item.state}
                                labelColor={item.labelColor}
                                label={item.checkBoxName}
                                onChange={() => {handleClickOfCheckbox(index, item.checkBoxName)}}
                              />
                            </>
                        )
                    })}
                </Box>
            </Box>
        )
    }

    return (
        <Box sx={{ border: "1px solid #CCD0D4", mt: 1.5, borderColor: GREY, boxShadow: 2 }}>
            <Box sx={{ width: "90%", marginLeft: "5%" }}>
                <Typography sx={{ mt: 2, width: "100%", textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                   Accounting: Add/Edit Account
                </Typography>
                <Typography sx={{ mt: 2, width: "100%", textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                   {t("YC_ACCOUNT_DETAILS")} 
                </Typography>
                {renderTopButtonsView()}
                {renderLabelAndTextView("title", t("YC_TITLE_LABEL"), inputData.title)}
                {renderLabelAndCheckboxView()}
                {renderLabelAndTextView("accountNumber", t("YC_ACCOUNT_NUMBER"), inputData.accountNumber)}
                <Box sx={{width: "100%", display: "flex", flexDirection: {xs: "column", sm: "column", lg: "row", xl: "row"}, justifyContent: "space-between"}}>
                   <Box sx={{width: {xs: "100%", sm: "100%", lg: "50%", xl: "50%"}}}>
                      {renderLabelAndTextView("swiftCode", t("YC_SWIFT_CODE"), inputData.swiftCode)}
                   </Box>
                   <Box sx={{width: {xs: "100%", sm: "100%", lg: "50%", xl: "50%"}}}>
                      {renderLabelAndTextView("ibanCode", t("YC_IBAN_CODE"), inputData.ibanCode, true)}
                   </Box>
                </Box>
                <Box sx={{width: "100%", display: "flex", flexDirection: {xs: "column", sm: "column", lg: "row", xl: "row"}, justifyContent: "space-between"}}>
                   <Box sx={{width: {xs: "100%", sm: "100%", lg: "50%", xl: "50%"}}}>
                      {renderLabelAndTextView("bankName", t("YC_BANK_NAME"), inputData.bankName)}
                   </Box>
                   <Box sx={{width: {xs: "100%", sm: "100%", lg: "50%", xl: "50%"}}}>
                      {renderLabelAndTextView("branchName", t("YC_BRANCH_NAME"), inputData.branchName, true)}
                   </Box>
                </Box>
                {renderLabelAndTextView("address", t("YC_ADDRESS"), inputData.address)}
                {renderBottonButtonsView()}
            </Box>
        </Box>
    );
}

export default Bank;
