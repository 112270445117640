import { Avatar, Box, Divider, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import CheckBox from "components/check-box/check-box";
import CustomButton from "components/custom-button/custom-button";
import CustomSwitch from "components/switch-button/switch-button";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HomeApi from "services/HomeAPi";
import { BLACK, BLACK_2, BLACK_3, BLUE, GREEN, GREY, GREY_3, GREY_4, PINK, RED, WHITE, YELLOW } from "utils/colors";
import { HEADER2, SUBHEADER1, SUBHEADER2, SUBHEADERBOLD, SUBHEADERBOLD2 } from "utils/fonts";
import { validateEmail } from "utils/helper";
import Notification from "views/Notifications/Notification";

const EditUserDialogStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "90%",
        overflowY: 'auto',
        OverflowX: "none",
        maxWidth: 700,
        minWidth: 450,
        background: "white",
    },
    dividerStyleVerticle: {
        borderColor: GREY,
        borderWidth: 0.5,
        opacity: 1,
    },
    otpViewTitle: {
        fontSize: 16,
        fontWeight: "500",
        color: "#1F2328",
        lineHeight: "24px",
    },
    titleText: {
        display: "flex",
        marginTop: 20,
        marginBottom: 20,
        justifyContent: "center",
        alignItems: "center",
    },
    subTitle: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "28px",
        textAlign: "left",
        color: BLACK,
    }

}))
function TaxSetting(props: any) {
    const { t } = useTranslation()
    const [subItemDataId, setSubItemDataId] = useState([])
    const [inputData, setInputData] = useState({
        taxValue: "",
    })

    const getUserId = () => {
        let userId
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            userId = details.id
        }
        return userId
    }

    // const postAccountDetails = async() => {
    //    const payload = {
    //       title: "test",
    //    }
    //    const response = await HomeApi.postAccountDetails(payload, getUserId())
    //    if (response.status === true) {
    //       Notification.notifySuccess(response.message)
    //    } else {
    //         Notification.notifyError(response.message)
    //    }

    // }

    const textFieldView = (key: string, value: string) => {
        return (
            <Box sx={{boxShadow: 2, 
            width: "90%"}}>
                <TextField style={{
                    width: "100%",
                }} label={""} variant="outlined"
                    size="small"
                    value={value}
                    onChange={(text) => {
                        const trimStr = text.target.value.trimStart();
                        if (key === "taxValue") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                taxValue: trimStr,
                            }));
                        } 
                    }}
                />
            </Box>
        )
    }

    const renderLabelAndTextView = (key: string, label:string, value: string) => {
        return(
            <Box sx={{ml:2, mt: 2, width: "60%", display: "flex", flexDirection: "column"}}>
                {textFieldView(key, value)}
                <Typography sx={{ 
                    width: "100%",
                    textAlign: "start",
                    padding: 2 }}>
                    {label}
                </Typography>
            </Box>
        )
    }

    return (
        // <Box sx={{ border: "1px solid #CCD0D4", mt: 1.5, borderColor: GREY, boxShadow: 2 }}>
            <Box sx={{ m:2, width: "95%", display: "flex", flexDirection: "row", background: GREY, boxShadow: 2 }}>
                <Box sx={{ m:2, width: "90%", marginLeft: "5%", display: "flex", flexDirection: "row", background: WHITE, boxShadow: 2 }}>
                    <Typography sx={{ mt: 2, ml:2, width: "20%", textAlign: "start" }}>
                    Sales Tax (VAT)
                    </Typography>
                    {renderLabelAndTextView("taxValue", "Default Tax (Vat) %", inputData.taxValue)}
                    <CustomButton
                            placeHolder={t("YC_MANUAL_SETTINGS")}
                            textTransform="none"
                            background={RED}
                            height="35px"
                            width={"150px"}
                            marginTop= "20px"
                            marginLeft="10px"
                            marginRight="50px"
                            borderRadius="7.2px"
                            buttonStyle={{
                                HEADER2
                            }}
                            onClick={() => { }}
                        />
                </Box>
            </Box>
        // </Box>
    );
}

export default TaxSetting;
