import * as React from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Button, Typography } from '@mui/material';
import { BLACK, BLACK_2, WHITE } from 'utils/colors';
import { useTranslation } from 'react-i18next';
import General from './general';
import StepFooter from '../dashboard-reusables/step-footer';
import Bank from './bank';
import TaxSetting from './tax-setting';
import Template from './template';
import AccountBalance from './accountBalance';
import Transfer from './transfer';

const useStyles = makeStyles(() => ({
  appText: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "23.3397px",
    lineHeight: "50px",
    color: "#FFFFFF",
    textAlign: "start",
  },
  inputBox: {
    float: "right",
    marginRight: "6px",
    marginLeft: "auto",
  }
}));

export default function AccountingComponents() {
  const { appText, inputBox } = useStyles();
  const { t } = useTranslation()
  const [isGeneralClicked, setIsGeneralClicked] = React.useState(true)
  const [isBankClicked, setIsBankClicked] = React.useState(false)
  const [isTaxSettingsClicked, setIsTaxSettingsClicked] = React.useState(false)
  const [isTemplateClicked, setIsTemplateClicked] = React.useState(false)
  const [isBalanceClicked, setIsBalanceClicked] = React.useState(false)
  const [isTransferClicked, setIsTransferClicked] = React.useState(false)

  return (
    <Box>
      <Box sx={{
        minHeight: "50px",
        borderRadius: "0px",
        backgroundColor: "#000000",
        marginTop: "16px",
      }}
      >
        <Typography sx={{ ml: 2.5 }} className={appText}>
          {t("YC_MY_ACCOUNTING")}
        </Typography>
      </Box>
      <Box sx={{
        borderRadius: "0px",
        marginTop: "15px",
        textAlign: "start",
      }}
      >
        {isGeneralClicked === true && (<Typography sx={{ ml: 2.5 }}>
         {t("YC_MY_ACCOUNTING_TEXT")}
        </Typography>)}
        {isBankClicked === true && (<Typography sx={{ ml: 2.5 }}>
         {t("YC_MY_ACCOUNTING_BANK_MODULE_TEXT")}
        </Typography>)}
        {isTaxSettingsClicked === true && (<Typography sx={{ ml: 2.5 }}>
         {t("YC_MY_ACCOUNTING_TAX_MODULE_TEXT")}
        </Typography>)}
        {isTemplateClicked === true && (<Typography sx={{ ml: 2.5 }}>
         {t("YC_MY_ACCOUNTING_TEMPLATE_MODULE_TEXT")}
        </Typography>)}
        {isBalanceClicked === true && (<Typography sx={{ ml: 2.5 }}>
         {t("YC_MY_ACCOUNTING_BALANCE_MODULE_TEXT")}
        </Typography>)}
        {isTransferClicked === true && (<Typography sx={{ ml: 2.5 }}>
         {t("YC_MY_ACCOUNTING_TRANSFER_MODULE_TEXT")}
        </Typography>)}
      </Box>
      <hr
        style={{
            color: BLACK,
            backgroundColor: BLACK,
            width: "100%",
            marginTop: 10,
            // marginLeft: "2.5%",
            height: 2
        }}
     />
      {/* <Box sx={{ border: "1px solid #CCD0D4", mt: 1.5, borderColor: GREY, boxShadow: 2}}> */}
        <Box sx={{width: "95%"}} >
          <Box sx={{width: "100%"}}>
            <Box sx={{display: "flex", justifyContent: "flex-start", width: "100%"}}>
                <Button sx={{
                     marginLeft: "20px",
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isGeneralClicked ? BLACK :  WHITE, 
                    color: isGeneralClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }}
                onClick={() => {
                        setIsGeneralClicked(true)
                        setIsBankClicked(false)
                        setIsTaxSettingsClicked(false)
                        setIsTemplateClicked(false)
                        setIsBalanceClicked(false)
                        setIsTransferClicked(false)
                  }}>{t("YC_GENERAL")}</Button>
                <Button sx={{
                     marginLeft: "10px",
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isBankClicked ? BLACK :  WHITE, 
                    color: isBankClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }} onClick={() => {
                  setIsGeneralClicked(false)
                  setIsBankClicked(true)
                  setIsTaxSettingsClicked(false)
                  setIsTemplateClicked(false)
                  setIsBalanceClicked(false)
                  setIsTransferClicked(false)
                  }}>{t("YC_BANK")}</Button>
                <Button sx={{
                     marginLeft: "10px",
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isTaxSettingsClicked ? BLACK :  WHITE, 
                    color: isTaxSettingsClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }} onClick={() => {
                  setIsGeneralClicked(false)
                  setIsBankClicked(false)
                  setIsTaxSettingsClicked(true)
                  setIsTemplateClicked(false)
                  setIsBalanceClicked(false)
                  setIsTransferClicked(false)
                  }}>{t("YC_TAX_SETTINGS")}</Button>
                <Button sx={{
                     marginLeft: "10px",
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isTemplateClicked ? BLACK :  WHITE, 
                    color: isTemplateClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }} onClick={() => {
                  setIsGeneralClicked(false)
                  setIsBankClicked(false)
                  setIsTaxSettingsClicked(false)
                  setIsTemplateClicked(true)
                  setIsBalanceClicked(false)
                  setIsTransferClicked(false)
                  }}>{t("YC_TEMPLATES")}</Button>
                  <Button sx={{
                     marginLeft: "10px",
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isBalanceClicked ? BLACK :  WHITE, 
                    color: isBalanceClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }} onClick={() => {
                  setIsGeneralClicked(false)
                  setIsBankClicked(false)
                  setIsTaxSettingsClicked(false)
                  setIsTemplateClicked(false)
                  setIsBalanceClicked(true)
                  setIsTransferClicked(false)
                  }}>{t("YC_BALANCE")}</Button>
                  <Button sx={{
                     marginLeft: "10px",
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isTransferClicked ? BLACK :  WHITE, 
                    color: isTransferClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }} onClick={() => {
                  setIsGeneralClicked(false)
                  setIsBankClicked(false)
                  setIsTaxSettingsClicked(false)
                  setIsTemplateClicked(false)
                  setIsBalanceClicked(false)
                  setIsTransferClicked(true)
                  }}>{t("YC_TRANSFER")}</Button>
            </Box>
            <hr
                style={{
                    color: BLACK,
                    backgroundColor: BLACK_2,
                    width: "100%",
                    marginTop: "-2px",
                    marginLeft: "10px",
                    height: 2
                }}
            />
          </Box>
        </Box>
        {isGeneralClicked === true && (<General/>)}
        {isBankClicked === true && (<Bank/>)}
        {isTaxSettingsClicked === true && (<TaxSetting/>)}
        {isTemplateClicked === true && (<Template/>)}
        {isBalanceClicked === true && (<AccountBalance/>)}
        {isTransferClicked === true && (<Transfer/>)}
      <StepFooter
        text={"Do you need assistance? "}
        link={"Click here to access Tutorials"}
      />
    </Box>
  );
}