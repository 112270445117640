import { Box, IconButton, Typography } from "@mui/material";
import CustomButton from "components/custom-button/custom-button";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { BLUE } from "utils/colors";
import { HEADER2 } from "utils/fonts";
import DeleteIcon from "../../assets/Delete.png"

const FileUploadWithDropzone = (props: any) => {
  const { t } = useTranslation()
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [errors, setErrors] = useState([]);

  const onDrop = useCallback((acceptedFiles: any, rejectedFiles: any) => {
    const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 MB in bytes
    const validFiles: any = [];
    const errorMessages: any = [];

    // Check accepted files
    acceptedFiles.forEach((file: any) => {
      if (file.size > MAX_FILE_SIZE) {
        errorMessages.push(`${file.name} exceeds 1 MB.`);
      } else {
        validFiles.push(file);
      }
    });

    // Collect errors from rejected files (optional)
    rejectedFiles.forEach(( file: any, errors: any ) => {
      if (errors.some((e: any) => e.code === "file-too-large")) {
        errorMessages.push(`${file.name} exceeds 1 MB.`);
      } else {
        errorMessages.push(`${file.name} is invalid.`);
      }
    });

    setUploadedFiles((prev: any) => [...prev, ...validFiles]);
    props.setUploadedFiles((prev: any) => [...prev, ...validFiles]);
    setErrors(errorMessages);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpg", ".png", ".gif"], //".jpeg",
      "application/pdf": [".pdf"],
    },
  });

   // Function to delete a file
   const handleDeleteFile = (index: any) => {
    setUploadedFiles((prevFiles: any) => prevFiles.filter((_ : any, i: any) => i !== index));
    props.setUploadedFiles((prevFiles: any) => prevFiles.filter((_ : any, i: any) => i !== index));
  };

  const renderIcon = (iconName: any) => {
    return (
        <img
          // style={{opacity: 0.4}}
          src={iconName}
          height={22}
          alt={"icon"}
        />
    );
  };

  return (
    <Box
    sx={{
      ml:2,
      width:{xs: "95%", sm: "95%", lg: "50%", xl: "50%"},
      textAlign: "start",
      display: "flex",
      flexDirection: "column",
  }}
  >
      {/* Drag-and-Drop Zone */}
      <Box
        {...getRootProps()}
        sx={{
          border: "2px solid #ccc",
          borderRadius: "4px",
          width:  "100%",
          textAlign: "start",
          p: 2,
          mb: 2,
          boxShadow: 4,
          display: "flex",
          flexDirection: "row",
      }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <>
                        <Typography variant="body1" sx={{ width: "150px" }}>{t("YC_DROP_FILES_OR")}</Typography>
                        <CustomButton
                            placeHolder={t("YC_CHOICE_FILE")}
                            textTransform="none"
                            background={BLUE}
                            height="30px"
                            width={"100px"}
                            marginLeft={20}
                            borderRadius="0px"
                            buttonStyle={{
                                HEADER2,
                                minWidth: "100px",
                            }}
                            onClick={() => { }}
                        />
                    </>
        )}
      </Box>

      {/* Display Uploaded Files */}
      <div style={{ marginTop: "2px" }}>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {uploadedFiles.map((file: any, index: any) => (
            <li
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                {file.name} - {file.size} bytes
              </div>
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteFile(index)}
                color="error"
              >
                {renderIcon(DeleteIcon)}
                {/* <DeleteIcon /> */}
              </IconButton>
            </li>
          ))}
        </ul>
        {errors.length > 0 && (
          <div style={{ color: "red", marginTop: "10px" }}>
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Box>
  );
};

export default FileUploadWithDropzone;
