import { Avatar, Box, Button, Divider, InputLabel, Link, MenuItem, Modal, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import CheckBox from "components/check-box/check-box";
import CustomButton from "components/custom-button/custom-button";
import CustomTitleSelect from "components/custom-title-select/custom-title-select";
import FileUpload from "components/file-uploader/image-uploader";
import PasswordField from "components/password-field/password-field";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import HomeApi from "services/HomeAPi";
import { BLACK, BLUE, DARKGREY, DARK_GRAY, GREY, PINK, RED, WHITE, YELLOW } from "utils/colors";
import { HEADER2, SUBHEADER1, SUBHEADERBOLD, SUBHEADERBOLD2 } from "utils/fonts";
import { validateEmail, validateMobilePhone } from "utils/helper";
import Notification from "views/Notifications/Notification";

const EditUserDialogStyle = makeStyles(() => ({
    themeStyle: { marginTop: 10, width: "100%", textAlign: "center", color: PINK },
    themeStyle2: { marginTop: 40, marginBottom: 10, width: "100%", textAlign: "center" },
    themeStyle3: { marginTop: 40, marginBottom: 400, width: "100%", textAlign: "center", color: RED }
}))
function Template(props: any) {
    const { themeStyle, themeStyle2, themeStyle3 } = EditUserDialogStyle();
    const { t } = useTranslation()

    const renderThemeView = (key: string, text1: string, text2: string, text3: string, text4: string) => {
        return(
            <Button sx={{width: "30%", background: GREY}}
             onClick={() => {}}>
                <Box sx={{width: "100%"}}>
                    <Typography className={themeStyle}>
                      {text1} 
                    </Typography>
                    <Typography className={themeStyle}>
                      {text2}
                    </Typography>
                    <Typography className={themeStyle}>
                      {text3}
                    </Typography>
                    <Typography className={themeStyle2}>
                      {text4}
                    </Typography>
                </Box>
            </Button>
        )
    }

    const renderThumnailView = () => {
        return(
            <Box sx={{width: "90%", marginLeft: "5%", background: GREY}}>
                <Typography className={themeStyle3}>
                   {t("YC_PREVIEW_THIMNAIL")} 
                </Typography>
            </Box>
        )
    }

    return (
        <Box sx={{ border: "1px solid #CCD0D4", mt: 1.5, borderColor: GREY, boxShadow: 2 }}>
            <Box sx={{ width: "90%", marginLeft: "5%" }}>
                <Typography sx={{ mt: 2, width: "100%", textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                    {t("YC_INVOICE_QUATION_TEMPLATE")}
                </Typography>
                <Box sx={{ mt: 1, mb: 2, width: "100%", height: 1.5, background: BLACK }} />
                <Typography sx={{ mt: 2, width: "100%", textAlign: "start" }}>
                    {`${t("YC_INVOICE_TEMPLATE_TEXT")+ ":"}`}
                </Typography>
                <Typography sx={{ mt: 2, width: "100%", textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                    {t("YC_INVOICE_TEMPLATES")}
                </Typography>
                <Typography sx={{ mt: 2, width: "100%", textAlign: "start" }}>
                    {t("YC_INVOICE_TEMPLATE_SELECT_TEXT")}
                </Typography>
                <Box sx={{mt:4, marginLeft: "10%", width: "80%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                   {renderThemeView("simple_theme", `${t("YC_SIMPLE_COLOUR_SCHEME")+ ":"}`, t("YC_YELLOW_BLACK_ON"), t("YC_WHITE_BACKGROUND"), t("YC_BLACK_WHITE"))}
                   {renderThemeView("yc_theme", `${"YC colour scheme"+":"}`, "Black on", t("YC_WHITE_BACKGROUND"), t("YC_DEFAULT_THEME"))}
                   {renderThemeView("brand_theme", `${t("YC_YOUR_COLOUR_SCHEME")+ ":"}`, t("YC_PRIMARY_SECONDARY_ON"), t("YC_WHITE_BACKGROUND"), t("YC_YOUR_BRAND"))}
                </Box>
            </Box>
            <Box sx={{mt:4, width: "90%", marginLeft: "5%" }}>
                <Typography sx={{ mt: 2, width: "100%", textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                    {t("YC_COLOR_STYLING_PREVIEW")}
                </Typography>
                <Box sx={{ mt: 1, mb: 2, width: "100%", height: 1.5, background: BLACK }} />
                <Typography sx={{ mt: 2, width: "100%", textAlign: "start" }}>
                {`${t("YC_COLOR_STYLING_TEXT") + " " }`}
                <Link href="#">{t("YC_COLOR_STYLING_TEXT_2")}</Link>
                {`${ " " + t("YC_COLOR_STYLING_TEXT_3")+"." }`}
                </Typography>
                <Box sx={{mt:4, ml:4, width: "95%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                   {renderThumnailView()}
                </Box>
            </Box>
            <Box sx={{mt:4, ml: 2, mb: 2, display: "flex", width: "95%", justifyContent: "flex-end" }}>
          <CustomButton
            placeHolder={t("YC_SAVE")}
            textTransform="none"
            background={BLUE}
            height="40px"
            width={100}
            // borderRadius="7.2px"
            buttonStyle={{
              HEADER2
            }}
            onClick={() => {}}
          />
        </Box>
        </Box>
    );
}

export default Template;
