
import React, { useRef, useState } from "react";
import {
    Box,
    Button,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BLACK, BLACK_2, BLUE, GREY, RED, WHITE } from 'utils/colors';
import { useTranslation } from 'react-i18next';
import StepFooter from '../dashboard-reusables/step-footer';
import CustomButton from "components/custom-button/custom-button";
import { HEADER2 } from "utils/fonts";
import plusIcon from "../../../assets/plusIcon.png";
import FileUploadWithDropzone from "components/file-uploader/file-upload-otherType";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";

const useStyles = makeStyles(() => ({
    appText: {
        fontFamily: 'Roboto',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "23.3397px",
        lineHeight: "50px",
        color: "#FFFFFF",
        textAlign: "start",
    },
    inputBox: {
        float: "right",
        marginRight: "6px",
        marginLeft: "auto",
    },
    typoStyle: { marginLeft: 10, mb: 2, textAlign: "start", width: "100%" }
}));

export default function FilesComponents() {
    const fileInputRef: any = useRef(null);
    const { appText, inputBox, typoStyle } = useStyles();
    const [uploadedFiles, setUploadedFiles] = useState<any>([]);
    const [processType, setProcessType] = useState("Business Setup");
    const [inputData, setInputData] = useState({
        fieldLable: "",
        description: "",
        setup: "Business",
        fileType: "Logo"
    })
    
    const { t } = useTranslation()

    const handleButtonClick = () => {
        // Programmatically click the hidden file input
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      };

      const handleFileChange = (event: any) => {
        const files = event.target.files; // Get selected files
        setUploadedFiles(files)
      }

    const handleProcessChange = (event: any) => {
        setProcessType(event.target.value);
    };

    const uploadFiles = async () => {
        let formData = new FormData();
        uploadedFiles.forEach((file: any, index: any) => {
            // formData.append(`files[${index}]`, file);
            formData.append('upload_file', file);
          });
        // formData.append('upload_file', uploadedFiles[0]);
        formData.append('feild_label', inputData.fieldLable)
        formData.append('description', inputData.description)
        formData.append('setup', inputData.setup)
        formData.append('file_type', inputData.fileType)
        
        const config = {     
            headers: { 'content-type': 'multipart/form-data' }
        }
        
        const response = await HomeApi.uploadFilesMyTools(formData, config);
        if (response.status === true) {
            Notification.notifySuccess(response.message)
        } else {
            Notification.notifyError(response.message)
        }
    };

    const renderIcon = (iconName: any) => {
        return (
            <img
                src={iconName}
                height={22}
                alt={"icon"}
            />
        );
    };

    const selectionView = (
        key: any,
        items: any,
        value: any,
        label: any,
        canSelect = true) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: "100%"
                }}
            >
                <Select
                    sx={{ height: 40, width: "100%", textAlign: "start" }}
                    value={value}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={(event: any) => {
                        if (key === "business_setup") {
                        } else if (key === "logo_files") {
                            // setInputData((prevFields: any) => ({
                            //     ...prevFields,
                            //     channelValue: event.target.value
                            // }))
                        }
                    }}
                    renderValue={(selected: any) => {
                        if (selected.length === 0) {
                            if (key === "business_setup") {
                                return <>{t("YC_BUSINESS_SETUP")}</>;
                            } else if (key === "logo_files") {
                                return <>{t("YC_LOGO_FILE")}</>;
                            }
                        }
                        return selected;
                    }}
                >
                    {items.map((item: any) => {
                        return (
                            <MenuItem
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Box>
        )
    }

    return (
        <Box>
            <Box sx={{
                minHeight: "50px",
                borderRadius: "0px",
                backgroundColor: "#000000",
                marginTop: "16px",
            }}
            >
                <Typography sx={{ ml: 2.5 }} className={appText}>
                    {t("YC_MY_UPLOAD_FUNCTION")}
                </Typography>
            </Box>
            <Box sx={{
                borderRadius: "0px",
                marginTop: "15px",
                textAlign: "start",
            }}
            >
                <Typography sx={{ ml: 2.5 }}>
                    {t("YC_FILES_TITILE_TEXT")}
                </Typography>
            </Box>
            <hr
                style={{
                    color: BLACK,
                    backgroundColor: BLACK,
                    width: "100%",
                    marginTop: 10,
                    // marginLeft: "2.5%",
                    height: 2
                }}
            />
            <Box sx={{
                mb:4,
                width: { xs: "100%", sm: "100%", lg: "80%", xl: "80%" }, display: "flex",
                flexDirection: "column",
                boxShadow: 4, marginLeft: { xs: "0%", sm: "0%", lg: "10%", xl: "10%" }
            }} >
                <Typography variant="h6" sx={{ mt: 2, ml: 1, textAlign: "start" }}>
                    {t("YC_FILE_UPLOAD")}
                </Typography>
                <Box sx={{
                    mt: 2, ml: 2, width: "95%", display: "flex",
                    flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" }
                }} >
                    {/* File Upload Section */}
                        <FileUploadWithDropzone setUploadedFiles={setUploadedFiles}/>
                    {/* File Information */}
                    <Box sx={{ width:  { xs: "100%", sm: "100%", lg: "50%", xl: "50%" }, mb: 2, ml: 4 }}>
                        <Typography variant="h6" className={typoStyle} color={RED}>
                            {t("YC_FILES")}
                        </Typography>
                        <Box sx={{ backgroundColor: BLACK, width: "30%", height: 2 }} />
                        <Typography variant="body2" color="textSecondary" className={typoStyle} mt={1}>
                            {t("YC_ALLOWED_FILE_EXTENSIONS")}: <b> jpg, gif, png, pdf</b>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" className={typoStyle} mt={1}>
                            Maximum file size: <b>1 MB</b>
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    mt: 2, ml: 2, width: "95%", display: "flex",
                    flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" }
                }} >
                    <Box sx={{ width: { xs: "95%", sm: "95%", lg: "50%", xl: "50%" }, mb: 2, ml: 2 }}>
                        <Typography variant="h6" className={typoStyle}>
                            {t("YC_GENERAL")}
                        </Typography>
                        <Box sx={{ backgroundColor: BLACK, width: "30%", height: 2 }} />
                        {/* General Section */}
                        <Box sx={{ width: "100%", mt: 2 }}>
                            <Typography variant="body2" className={typoStyle} mt={1}>
                                {t("YC_FIELD_LABEL")}
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                sx={{ height: "50px" }}
                                onChange={(text) => {
                                    const trimStr = text.target.value.trimStart();
                                    setInputData((prevFields) => ({
                                        ...prevFields,
                                        fieldLable: trimStr,
                                    }));
                                }}
                            />
                            <Typography variant="body2" className={typoStyle} mt={1}>
                                {t("YC_DESCRIPTION")}
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                onChange={(text) => {
                                    const trimStr = text.target.value.trimStart();
                                    setInputData((prevFields) => ({
                                        ...prevFields,
                                        description: trimStr,
                                    }));
                                }}
                            />
                        </Box>
                        <Box sx={{ mt: 2, display: "flex", flexDirection: "row" }}>
                            {/* <IconButton
                                sx={{ml: 0.5}}
                                aria-label="delete"
                                onClick={() => {}}
                                color="error"
                            >
                                {renderIcon(plusIcon)}
                            </IconButton>
                            <Typography sx={{ marginLeft: "-5px", mt: 1, textAlign: "start", width: "100%" }}>
                                {t("YC_ADDITIONAL_FILES_UPLOAD")}
                            </Typography> */}
                            {/* <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }} // Hide the input
                                onChange={handleFileChange}
                            /> */}
                        </Box>
                    </Box>
                    <Box sx={{ width: { xs: "100%", sm: "100%", lg: "50%", xl: "50%" }, mb: 2, ml: 4 }}>
                        <Typography variant="h6" className={typoStyle} color={RED}>
                            {t("YC_PROCESS")}
                        </Typography>
                        <Box sx={{ backgroundColor: BLACK, width: "30%", height: 2 }} />
                        <Typography variant="body2" width={"50%"} textAlign={"start"} ml={2} mt={2}>
                            {t("YC_MY_FILE_PROCESS_TEXT")}
                        </Typography>
                        <Box sx={{ width: "90%", display: "flex", flexDirection: "row", background: WHITE }}>
                            <Box sx={{ width: "40%", height: "40px", marginTop: "10px" }}>
                                {selectionView("business_setup", [], "", t("YC_PAYMENT_TYPES"))}
                            </Box>
                            <Box sx={{ ml: 1, width: "40%", height: "40px", marginTop: "10px" }}>
                                {selectionView("logo_files", [], "", t("YC_CHANNEL"))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{mt:2, width: { xs: "90%", sm: "90%", lg: "48%", xl: "48%" }, mb: 10, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <CustomButton
                            placeHolder={t("YC_CANCEL")}
                            textTransform="none"
                            background={GREY}
                            height="30px"
                            width={"100px"}
                            borderRadius="0px"
                            buttonStyle={{
                                HEADER2,
                                minWidth: "100px",
                            }}
                            onClick={() => { }}
                        />
                        <CustomButton
                            placeHolder={t("YC_SUBMIT")}
                            textTransform="none"
                            background={BLUE}
                            height="30px"
                            width={"100px"}
                            marginLeft={20}
                            borderRadius="0px"
                            buttonStyle={{
                                HEADER2,
                                minWidth: "100px",
                            }}
                            onClick={() => {uploadFiles()}}
                        />
                </Box>
                {/* {<FileUploadWithDropzone/>} */}
                {/* {FileUploadForm()} */}
            </Box>
            <StepFooter
                text={"Do you need assistance? "}
                link={"Click here to access Tutorials"}
            />
        </Box>
    );
}