import { Box, Button, Link, ListItemIcon, Modal, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import HomeApi from "services/HomeAPi";
import { BLACK, BLUE, BLUE_2, LINK, RED, WHITE, YELLOW } from "utils/colors";
import Info from "../../../assets/info.png"
import { useTranslation } from "react-i18next";
import SupportIcon from "../../../assets/helpIcon.png";
import CustomButton from "components/custom-button/custom-button";
const customStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
        justifyContent: "flex-around",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginLeft: "40px",
        marginTop: "20px",
        width: '35%',
        background: "#FFFFFF",
    },
}))
function Balance(props: any) {
    const { t } = useTranslation()
    const [isDismissNotice, setIsDismissNotice] = useState(false)
    const { boxView } = customStyle();
    const width = (window.innerWidth * 80) / 100;
    const [invoiceList, setInvoiceList] = useState([{
        id: 1,
        invoiceDate: "04/01/2023",
        invoiceNo: "ym-2016-2023",
        invoiceTotal: "MUR 900.00",
        service: "Yellow Market",
        status: "Soon",
        details: ""
    },
    {
        id: 2,
        invoiceDate: "04/11/2023",
        invoiceNo: "yp-7826-2022",
        invoiceTotal: "MUR 3,000.00",
        service: "Yellow Pages",
        status: "Paid",
        details: "More Details"
    }])
    const [resourceCodeList, setResourceCodeList] = useState(props.resourceCodeList)
    const columns = [
        { field: 'Id', headerName: '', width: 0.01 },
        { field: 'invoiceDate', headerName: 'Invoice Date', width: width / 6 },
        {
            field: 'invoiceNo', headerName: 'Invoice No.', width: width / 6, renderCell: (params: any) => <Button
                sx={{
                    textTransform: "none",
                    color: RED,
                    // "&:hover": {
                    //     color: YELLOW,
                    // },
                }}
                variant="text"
                onClick={() => { }}
            >{params.value}</Button>
        },
        { field: 'invoiceTotal', headerName: 'Invoice Total', width: width / 6 },
        { field: 'service', headerName: 'Service', width: width / 6 },
        { field: 'status', headerName: 'Status', width: width / 6 },
        {
            field: 'details', headerName: 'Details', width: width / 6,
            renderCell: (params: any) => <Button
                sx={{
                    textTransform: "none",
                    "&:hover": {
                        color: YELLOW,
                    },
                }}
                variant="text"
                onClick={() => { }}
            >(More Details)</Button>
        }
    ];
    const isResourseAvailable = (resourseCode: string) => {
        let result
        if (resourceCodeList !== null) {
            result = resourceCodeList.find((resourse: any) => resourse.includes(resourseCode));
        }
        return result === resourseCode ? true : false
    }
    // useEffect(() => {
    //     if(props.userList.length === 0){
    //         getUserList()
    //     }
    //   }, [])
    // const getUserList = async () => {
    //     let userId;
    //     const loginUserDetails = localStorage.getItem("user_Details")
    //     if (loginUserDetails){
    //     const details = JSON.parse(loginUserDetails)
    //     userId = details.id
    //     }
    //     const response = await HomeApi.getUserList(userId);
    //     if (response.status === true) {
    //         setUserList(response.data)
    //         props.setIsUserAvailable(true)
    //     }
    // }
    return (
        <Box className="boxView">
           {!isDismissNotice && <Box sx={{ marginLeft: "10px", width: "95%", background: BLUE_2, display: "flex", flexDirection: {xs: "column", sm: "column", lg: "row", xl: "row"}, justifyContent: "space-between" }}>
                <Box sx={{ height: "100%", width: "80%", display: "flex", flexDirection: "row" }}>
                    <Box sx={{ height: "100%", width: 5, background: YELLOW }} />
                    <img
                        src={Info}
                        height={20}
                        style={{ marginLeft: "15px", marginTop: "10px" }}
                        alt={"unlock icon"}
                    />
                    <Typography sx={{ ml: 1, mt: 1.5, color: BLACK, fontSize: 12 }}>
                        {`${t("YC_BALANCE_INFORMATION_NOTICE") + " "}`}
                        <Link sx={{ mx: 1, color: LINK, marginLeft: 1, "&:hover": { cursor: "pointer" } }} variant="h6" fontSize={12}>
                            {t("YC_CUSTOMER_SUPPORT")}
                        </Link>
                    </Typography>
                </Box>
                 <Box sx={{ height: "100%", width: { xs: "40%", sm: "40%", lg: "20%", xl: "20%" } }}>
                    <Button
                        sx={{
                            mt: 0.5,
                            mr: 1,
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "#fff",
                            },
                        }}
                        variant="text"
                        onClick={() => {setIsDismissNotice(true)}}
                    >
                        <Link sx={{ mx: 1, color: BLUE }} variant="h6" fontSize={12}>
                            {t("YC_DISMISS_NOTICE")}
                        </Link>
                    </Button>
                </Box>
            </Box>
            }
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
                justifyContent: "flex-around",
                alignItems: "flex-start",
                borderRadius: "7.2px",
                marginLeft: "10px",
                marginTop: "20px",
                marginBottom: "20px",
                width: '95%',
                background: "#FFFFFF",
            }}
            >
                <Box sx={{ mt: 2, ml: 2, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "80%", lg: "90%", xl: "90%" } }}>
                    <Typography sx={{ ml: 4, textAlign: "start" }}>Y-Click Balance</Typography>
                    <Box sx={{ mt: 2, mb: 2, ml: 2, display: "flex", flexDirection: "column", width: { xs: "90%", sm: "80%", lg: "50%", xl: "50%" }, boxShadow: 4 }}>
                        <Box sx={{ ml: 2, display: "flex", flexDirection: "row", width: "30%", alignItems: "flex-start" }}>
                            <Typography style={{ marginLeft: "5px", marginTop: "8px", fontSize: "14px" }}>
                                Credits
                            </Typography>
                            <Tooltip title={"Available balance display"}>
                                <ListItemIcon sx={{ marginTop: "10px", marginLeft: "10px" }}>
                                    <img src={SupportIcon} height={15} alt={"icons"} />
                                </ListItemIcon>
                            </Tooltip>
                        </Box>
                        <Typography sx={{ ml: 2.5, marginTop: "8px", fontSize: "16px", textAlign: "start" }}>
                            MUR 9,000.00
                        </Typography>
                        <Typography sx={{ ml: 2.5, marginTop: "8px", fontSize: "12px", textAlign: "start" }}>
                            Available for withdrawal or purchases.
                        </Typography>
                        {/* <Box sx={{mt: 3, ml: 2.5,  width: "90%", height: 1.5, background: BLACK}}/> */}
                        <hr
                            style={{
                                color: BLACK,
                                background: BLACK,
                                width: "95%",
                                marginTop: 25,
                                marginLeft: "2.5%",
                                height: 1.5
                            }}
                        />
                        <Box sx={{ ml: 2, display: "flex", flexDirection: "row", width: "80%", alignItems: "flex-start" }}>
                            <Typography style={{ marginLeft: "5px", marginTop: "8px", fontSize: "12px" }}>
                                From cancelled orders
                            </Typography>
                            <Tooltip title={"Available balance display"}>
                                <ListItemIcon sx={{ marginTop: "10px", marginLeft: "10px" }}>
                                    <img src={SupportIcon} height={15} alt={"icons"} />
                                </ListItemIcon>
                            </Tooltip>
                        </Box>
                        <Typography sx={{ ml: 2.5, mt: 1, fontSize: "16px", textAlign: "start" }}>
                            MUR 0.00
                        </Typography>
                        <Typography sx={{ ml: 2.5, mb: 2, mt: 1, fontSize: "12px", textAlign: "start" }}>
                            Use for purchases.
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ mt: 2, ml: 2, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "80%", lg: "90%", xl: "90%" } }}>
                    <Typography sx={{ ml: 4, textAlign: "start" }}>Freelance Credits</Typography>
                    <Box sx={{ mt: 2, display: "flex", flexDirection: {xs: "column", sm: "column", lg: "row", xl: "row"}, width: "100%", justifyContent: "space-between" }}>
                    <Box sx={{ mt: 2, mb: 2, ml: 2, display: "flex", flexDirection: "column", width: { xs: "90%", sm: "80%", lg: "50%", xl: "50%" }, boxShadow: 4 }}>
                        <Box sx={{ display: "flex", flexDirection: "row", width: "95%", justifyContent: "space-between" }}>
                            <Box sx={{ ml: 2, display: "flex", flexDirection: "row", width: "30%", alignItems: "flex-start" }}>
                                <Typography style={{ marginTop: "8px", fontSize: "14px" }}>
                                    Earnings
                                </Typography>
                                <Tooltip title={"Available balance display"}>
                                    <ListItemIcon sx={{ marginTop: "10px", marginLeft: "10px" }}>
                                        <img src={SupportIcon} height={15} alt={"icons"} />
                                    </ListItemIcon>
                                </Tooltip>
                            </Box>
                            <Typography sx={{ marginTop: "8px", textAlign: "start" }}>
                                <Link sx={{ mx: 1, color: LINK, "&:hover": { cursor: "pointer" } }} fontSize={14}>
                                    {t("YC_YOUR_FREELANCE_PAGE")}
                                </Link>
                            </Typography>
                        </Box>
                        <Typography sx={{ ml: 2.5, marginTop: "8px", fontSize: "16px", textAlign: "start" }}>
                            MUR 0.00
                        </Typography>
                        <Typography sx={{ mt: 1, ml: 2.5, marginTop: "8px", fontSize: "12px", textAlign: "start" }}>
                            Available for withdrawal or purchases.
                        </Typography>
                        <hr
                            style={{
                                color: BLACK,
                                background: BLACK,
                                width: "95%",
                                marginTop: 25,
                                marginLeft: "2.5%",
                                height: 1.5
                            }}
                        />
                        <Box sx={{ ml: 2.5, display: "flex", flexDirection: "row", width: "80%", alignItems: "flex-start" }}>
                            <Typography sx={{ marginTop: "8px", fontSize: "16px", textAlign: "start", width: "80%" }}>
                                Like to earn some credits?
                            </Typography>
                            {/* <Tooltip title={"Available balance display"}>
                            <ListItemIcon sx={{marginTop:"10px", marginLeft: "10px"}}>
                            <img src={SupportIcon} height={15} alt={"icons"} />
                            </ListItemIcon>
                        </Tooltip> */}
                        </Box>
                        <Typography sx={{ ml: 2.5, marginTop: "8px", fontSize: "16px", textAlign: "start" }}>
                            Refer people you know and everyone benefits!
                        </Typography>
                        <CustomButton
                            placeHolder={t("YC_EARN_Y_CLICK_CREDITS")}
                            textTransform="none"
                            background={BLUE}
                            titleColor={WHITE}
                            height={40}
                            marginTop={35}
                            marginLeft={20}
                            marginBottom={20}
                            width={"50%"}
                            borderRadius={7.2}
                            onClick={() => { }}
                            hoverColor={WHITE}
                            buttonStyle={{ backgroundColor: BLUE, fontSize: 14 }}
                        />
                    </Box>
                    <CustomButton
                            placeHolder={t("YC_SAVE_CHANGES")}
                            textTransform="none"
                            background={BLUE}
                            titleColor={WHITE}
                            height={40}
                            marginTop={"25%"}
                            marginLeft={20}
                            marginBottom={20}
                            width={"25%"}
                            borderRadius={7.2}
                            onClick={() => { }}
                            hoverColor={WHITE}
                            buttonStyle={{ backgroundColor: BLUE, fontSize: 14 }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
export default Balance;