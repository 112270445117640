import { Box, Button, Link, ListItemIcon, Modal, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import HomeApi from "services/HomeAPi";
import { BLACK, BLUE, BLUE_2, LINK, RED, WHITE, YELLOW } from "utils/colors";
import { useTranslation } from "react-i18next";
import SupportIcon from "../../../assets/helpIcon.png";
import CustomButton from "components/custom-button/custom-button";
import { SUBHEADER2, SUBHEADERBOLD2 } from "utils/fonts";

const customStyle = makeStyles(() => ({
    textViewStyle: { marginLeft: "2.5%", width: "90%", textAlign: "start", fontSize: "12px", },
    textViewStyleWithTopBottom: { marginLeft: "2.5%", marginTop: 20, marginBottom: 20, width: "90%", textAlign: "start", fontSize: "12px", }
}))
function AccountBalance(props: any) {
    const { t } = useTranslation()
    const { textViewStyle, textViewStyleWithTopBottom } = customStyle();
    const [availableBalance, setAvailableBalance] = useState("MUR 9,000.00")
    const [securityDeposit, setSecurityDeposit] = useState("MUR 1,000.00")
    const [earnings, setEarnings] = useState("MUR 0.00")
    const [pendingRelease, setPendingRelease] = useState("MUR 1,000.00")
    return (
        <Box className="boxView">
            {/* <Box sx={{width: "100%", display: "flex", flexDirection: {xs: "column", sm: "column", lg: "row", xl: "row"}}}> */}
            <Box sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
                justifyContent: "flex-around",
                alignItems: "flex-start",
                borderRadius: "7.2px",
                marginLeft: "10px",
                marginTop: "20px",
                marginBottom: "20px",
                width: '95%',
                background: "#FFFFFF",
            }}
            >
                <Box sx={{ mt: 2, mb: 2, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "100%", lg: "60%", xl: "60%" } }}>
                    <Box sx={{ mt: 2, ml: 2, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "100%", lg: "90%", xl: "90%" } }}>
                        <Typography sx={{ ml: 4, textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>Y-Click Balance</Typography>
                        <Box sx={{ mt: 2, mb: 2, ml: 2, display: "flex", flexDirection: "column", width: "90%", boxShadow: 4 }}>
                            <Box sx={{ ml: 2, display: "flex", flexDirection: "row", width: "80%", alignItems: "flex-start" }}>
                                <Typography style={{ marginLeft: "5px", marginTop: "20px", fontSize: "14px" }}>
                                    Yellow Token Credits
                                </Typography>
                                <Tooltip title={"Available balance display"}>
                                    <ListItemIcon sx={{ marginTop: "22px", marginLeft: "10px" }}>
                                        <img src={SupportIcon} height={15} alt={"icons"} />
                                    </ListItemIcon>
                                </Tooltip>
                            </Box>
                            <Typography sx={{ ml: 2.5, mt: 3, fontSize: "20px", textAlign: "start" }}>
                                {availableBalance}
                            </Typography>
                            <Typography sx={{ ml: 2.5, marginTop: "8px", fontSize: "12px", textAlign: "start" }}>
                                {t("YC_AVAILABLE_FOR_WITHDRAWAL_PURCHASE")}
                            </Typography>
                            {/* <Box sx={{mt: 3, ml: 2.5,  width: "90%", height: 1.5, background: BLACK}}/> */}
                            <hr
                                style={{
                                    color: BLACK,
                                    background: BLACK,
                                    width: "95%",
                                    marginTop: 25,
                                    marginLeft: "2.5%",
                                    height: 1.5
                                }}
                            />
                            <Box sx={{ ml: 2, display: "flex", flexDirection: "row", width: "80%", alignItems: "flex-start" }}>
                                <Typography style={{ marginLeft: "5px", marginTop: "8px", fontSize: "14px" }}>
                                    {t("YC_FROM_DISPUTE_ORDERS")}
                                </Typography>
                                <Tooltip title={"Available balance display"}>
                                    <ListItemIcon sx={{ marginTop: "10px", marginLeft: "10px" }}>
                                        <img src={SupportIcon} height={15} alt={"icons"} />
                                    </ListItemIcon>
                                </Tooltip>
                            </Box>
                            <Typography sx={{ ml: 2.5, mt: 3, fontSize: "20px", textAlign: "start" }}>
                                {securityDeposit}
                            </Typography>
                            <Typography sx={{ ml: 2.5, mb: 2, mt: 1, fontSize: "12px", textAlign: "start" }}>
                                {t("YC_SECURITY_DEPOSIT")}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, ml: 2, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "100%", lg: "90%", xl: "90%" } }}>
                        <Typography sx={{ ml: 4, textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>{t("YC_SERVICE_CREDIT")}</Typography>
                        <Box sx={{ mt: 2, display: "flex", flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" }, width: "100%", justifyContent: "space-between" }}>
                            <Box sx={{ mt: 2, mb: 2, ml: 2, display: "flex", flexDirection: "column", width: "90%", boxShadow: 4 }}>
                                <Box sx={{ display: "flex", flexDirection: "row", width: "95%", justifyContent: "space-between" }}>
                                    <Box sx={{ ml: 2, display: "flex", flexDirection: "row", width: "30%", alignItems: "flex-start" }}>
                                        <Typography style={{ marginTop: "20px", fontSize: "14px" }}>
                                            {t("YC_EARNINGS")}
                                        </Typography>
                                        <Tooltip title={"Available balance display"}>
                                            <ListItemIcon sx={{ marginTop: "22px", marginLeft: "10px" }}>
                                                <img src={SupportIcon} height={15} alt={"icons"} />
                                            </ListItemIcon>
                                        </Tooltip>
                                    </Box>
                                    <Typography sx={{ marginTop: "8px", textAlign: "start" }}>
                                        <Link sx={{ mx: 1, color: LINK, "&:hover": { cursor: "pointer" } }} fontSize={14}>
                                            {t("YC_YOUR_FREELANCE_PAGE")}
                                        </Link>
                                    </Typography>
                                </Box>
                                <Typography sx={{ ml: 2.5, mt: 3, fontSize: "20px", textAlign: "start" }}>
                                    {earnings}
                                </Typography>
                                <Typography sx={{ mt: 1, ml: 2.5, marginTop: "8px", fontSize: "12px", textAlign: "start" }}>
                                    {t("YC_AVAILABLE_FOR_WITHDRAWAL_PURCHASE")}
                                </Typography>
                                <hr
                                    style={{
                                        color: BLACK,
                                        background: BLACK,
                                        width: "95%",
                                        marginTop: 25,
                                        marginLeft: "2.5%",
                                        height: 1.5
                                    }}
                                />
                                <Box sx={{ ml: 2, display: "flex", flexDirection: "row", width: "80%", alignItems: "flex-start" }}>
                                    <Typography style={{ marginLeft: "5px", marginTop: "8px", fontSize: "14px" }}>
                                        {t("YC_INCOMPLETE_WORK")}
                                    </Typography>
                                    <Tooltip title={"Available balance display"}>
                                        <ListItemIcon sx={{ marginTop: "10px", marginLeft: "10px" }}>
                                            <img src={SupportIcon} height={15} alt={"icons"} />
                                        </ListItemIcon>
                                    </Tooltip>
                                </Box>
                                <Typography sx={{ ml: 2.5, mt: 3, fontSize: "20px", textAlign: "start" }}>
                                    {pendingRelease}
                                </Typography>
                                <Typography sx={{ ml: 2.5, mb: 2, mt: 1, fontSize: "12px", textAlign: "start" }}>
                                    {t("YC_PENDING_RELEASE")}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ mt: 8.5, mb: 2, ml:{ xs: 4, sm: 4, lg: 1, xl: 1 }, display: "flex", flexDirection: "column", width: { xs: "90%", sm: "90%", lg: "36%", xl: "36%" }, boxShadow: 4 }}>
                    <Typography sx={{ marginLeft: "2.5%", mt: 2, width: "90%", textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                        {t("YC_EARN_YELLOW_TOKENS")}
                    </Typography>
                    <Typography className={textViewStyleWithTopBottom}>
                        {t("YC_IMPLEMENTED_BLOCKCHAIN")}
                    </Typography>
                    <Typography className={textViewStyle}>
                        1. {t("YC_DIGITISATION_ORGANISATION")}
                    </Typography>
                    <Typography className={textViewStyle}>
                        2. {t("YC_DIGITISATION_PAYOUTS")}
                    </Typography>
                    <Typography className={textViewStyle}>
                        3. {t("YC_WORK_PROCESS_PAYOUT")}
                    </Typography>
                    <Typography className={textViewStyle}>
                        4. {t("YC_ONLINET_TRADE_LOCALLY_AND_CROSS_BORDER")}
                    </Typography>
                    <Typography className={textViewStyleWithTopBottom}>
                        {t("YC_FURTHER_SUPPORT_TEXT")}
                    </Typography>
                    <Typography className={textViewStyleWithTopBottom}>
                        {t("YC_FURTHER_SUPPORT_TEXT_2")}
                    </Typography>
                    <Typography className={textViewStyleWithTopBottom}>
                        {t("YC_HEARAFTER_WE_WILL_LAUNCH")}
                    </Typography>
                    <Typography className={textViewStyle}>
                        1. {"Y-Click Referral Programs"}
                    </Typography>
                    <Typography className={textViewStyle}>
                        2. {"Yellow Markets Special Coupons"}
                    </Typography>
                    <Typography className={textViewStyleWithTopBottom}>
                        {t("YC_YELLOW_TOKEN_TEXT")}
                    </Typography>
                    <Typography sx={{ marginLeft: "2.5%", width: "90%", textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                        {t("YC_LIKE_TO_EARN_CREDIT")}
                    </Typography>
                    <Typography className={textViewStyleWithTopBottom}>
                        {t("YC_REFER_PEOPLE_YOU_KNOW")}
                    </Typography>
                    <CustomButton
                        placeHolder={t("YC_EARN_YCLICK_CREDIT")}
                        textTransform="none"
                        background={BLUE}
                        titleColor={WHITE}
                        height={40}
                        marginLeft={20}
                        marginBottom={20}
                        width={200}
                        borderRadius={7.2}
                        onClick={() => { }}
                        hoverColor={WHITE}
                        buttonStyle={{ backgroundColor: BLUE, fontSize: 14 }}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default AccountBalance;