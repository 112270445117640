import { Box, Button, Link, ListItemIcon, Modal, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import HomeApi from "services/HomeAPi";
import { BLACK, BLUE, BLUE_2, GREY, LINK, RED, WHITE, YELLOW } from "utils/colors";
import Info from "../../../assets/info.png"
import { useTranslation } from "react-i18next";
import DropDownArrow from "../../../assets/dropDownArrow.png";
import CustomButton from "components/custom-button/custom-button";
import { BODY1, SUBHEADERBOLD, SUBHEADERBOLD2 } from "utils/fonts";
const customStyle = makeStyles(() => ({
    textStyle: { fontSize: "16px", textAlign: "start", width: "50%", fontWeight: 500 },
    textStyle2: { fontSize: "16px", textAlign: "start", width: "50%" },
    tableHeaderStyle: {padding: '10px', textAlign: "start", color: BLUE },
    tableRowStyle: {padding: '10px', textAlign: "start" }
}))
function ViewMoreBillingDetails(props: any) {
    const { t } = useTranslation()
    const { textStyle, textStyle2, tableHeaderStyle, tableRowStyle } = customStyle();
    const [isDismissNotice, setIsDismissNotice] = useState(false)
    const width = (window.innerWidth * 80) / 100;
    const invoiceData = {
        id: 'INV-001',
        date: '2024-10-21',
        customer: {
          name: 'John Doe',
          address: '123 Main St, Anytown, USA'
        },
        items: [
          { description: 'Yellow Market Plan', date: '2021 - 12  - 03', amount: 'MUR 9,000.00' },
        ],
        total: 35.00
      }
    const [resourceCodeList, setResourceCodeList] = useState(props.resourceCodeList)
    const columns = [
        { field: 'Id', headerName: '', width: 0.01 },
        { field: 'invoiceDate', headerName: 'Invoice Date', width: width / 6 },
        {
            field: 'invoiceNo', headerName: 'Invoice No.', width: width / 6, renderCell: (params: any) => <Button
                sx={{
                    textTransform: "none",
                    color: RED,
                    // "&:hover": {
                    //     color: YELLOW,
                    // },
                }}
                variant="text"
                onClick={() => { }}
            >{params.value}</Button>
        },
        { field: 'invoiceTotal', headerName: 'Invoice Total', width: width / 6 },
        { field: 'service', headerName: 'Service', width: width / 6 },
        { field: 'status', headerName: 'Status', width: width / 6 },
        {
            field: 'details', headerName: 'Details', width: width / 6,
            renderCell: (params: any) => <Button
                sx={{
                    textTransform: "none",
                    "&:hover": {
                        color: YELLOW,
                    },
                }}
                variant="text"
                onClick={() => { }}
            >(More Details)</Button>
        }
    ];
    const isResourseAvailable = (resourseCode: string) => {
        let result
        if (resourceCodeList !== null) {
            result = resourceCodeList.find((resourse: any) => resourse.includes(resourseCode));
        }
        return result === resourseCode ? true : false
    }
    // useEffect(() => {
    //     if(props.userList.length === 0){
    //         getUserList()
    //     }
    //   }, [])
    // const getUserList = async () => {
    //     let userId;
    //     const loginUserDetails = localStorage.getItem("user_Details")
    //     if (loginUserDetails){
    //     const details = JSON.parse(loginUserDetails)
    //     userId = details.id
    //     }
    //     const response = await HomeApi.getUserList(userId);
    //     if (response.status === true) {
    //         setUserList(response.data)
    //         props.setIsUserAvailable(true)
    //     }
    // }
    const underlineView = () => {
        return (
            <hr
                style={{
                    color: BLACK,
                    background: BLACK,
                    width: "95%",
                    marginTop: 10,
                    marginLeft: "2.5%",
                    height: 1.5
                }}
            />
        )
    }
    const fullViewUnderline = () => {
        return (
            <hr
                style={{
                    color: BLACK,
                    background: BLACK,
                    width: "100%",
                    marginTop: 10,
                    height: 1.5
                }}
            />
        )
    }
    const renderDetailItemView = (label: string, value: string, isBold = false) => {
        return (
            <Box sx={{ ml: 2.5, display: "flex", flexDirection: "row", width: "92%" }}>
                <Typography sx={{ fontSize: "16px", textAlign: "start", width: "50%", fontStyle: isBold ? SUBHEADERBOLD2 : null }}>
                    {label}
                </Typography>
                <Typography sx={{ fontSize: "16px", textAlign: "end", width: "50%", fontStyle: isBold ? SUBHEADERBOLD2 : null }}>
                    {value}
                </Typography>
            </Box>
        )
    }
    const renderAccountStatusItemView = (label: string, value: string) => {
        return (
            <Box sx={{ ml: 2.5, display: "flex", flexDirection: "row", width: "95%" }}>
                <Typography className={textStyle}>
                    {label}
                </Typography>
                <Typography className={textStyle2}>
                    {value}
                </Typography>
            </Box>
        )
    }
    return (
        <Box className="boxView">
            <Typography sx={{ mt: 1, width: "100%", color: BLACK, fontStyle: SUBHEADERBOLD, textAlign: "start", paddingLeft: 5, mb: 2 }}>
                {`${t("YC_BILLING") + " " + ">" + " " + "Invoice No. xxxx" + " " + ">" + " " + "Details"}`}
            </Typography>
            {!isDismissNotice && <Box sx={{ marginLeft: "10px", width: "95%", background: BLUE_2, display: "flex", flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" }, justifyContent: "space-between" }}>
                <Box sx={{ height: "100%", width: "80%", display: "flex", flexDirection: "row" }}>
                    <Box sx={{ height: "100%", width: 5, background: YELLOW }} />
                    <img
                        src={Info}
                        height={20}
                        style={{ marginLeft: "15px", marginTop: "10px" }}
                        alt={"unlock icon"}
                    />
                    <Typography sx={{ ml: 1, mt: 1.5, color: BLACK, fontSize: 12 }}>
                        {`${t("YC_YOUR_SUBSCRIPTION_INACTIVE") + " " + t("YC_GO_TO_YOUR") + " "}`}
                        <Link sx={{ mx: 1, color: LINK, marginLeft: 1, "&:hover": { cursor: "pointer" } }} variant="h6" fontSize={12}>
                            {t("YC_ACCOUNT_PAGE")}
                        </Link>
                        {`${" " + t("YC_SUBSCRIPTION_TEXT_CURRENTLY_UNABLE_ACCESS")}`}
                    </Typography>
                </Box>
                <Box sx={{ height: "100%", width: { xs: "40%", sm: "40%", lg: "20%", xl: "20%" } }}>
                    <Button
                        sx={{
                            mt: 0.5,
                            mr: 1,
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "#fff",
                            },
                        }}
                        variant="text"
                        onClick={() => {setIsDismissNotice(true) }}
                    >
                        <Link sx={{ mx: 1, color: BLUE }} variant="h6" fontSize={12}>
                            {t("YC_DISMISS_NOTICE")}
                        </Link>
                    </Button>
                </Box>
            </Box>}
            <Typography sx={{ mt: 2, ml: 4, textAlign: "start" }}>{t("YC_HERE_FULL_ACCOUNT_DETAILS")}</Typography>
            <Box sx={{
                display: "flex",
                flexDirection: {xs: "column", sm: "column", lg: "row", xl: "row"},
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
                justifyContent: "flex-around",
                alignItems: "flex-start",
                borderRadius: "7.2px",
                marginLeft: "10px",
                marginTop: "20px",
                marginBottom: "20px",
                width: '95%',
                background: "#FFFFFF",
            }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-around",
                    alignItems: "flex-start",
                    borderRadius: "7.2px",
                    marginLeft: "10px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    width: '80%',
                    background: "#FFFFFF",
                }}
                >
                    <Box sx={{ mt: 2, ml: 1, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "100%", lg: "90%", xl: "90%" } }}>
                        <Box sx={{ mt: 2, mb: 2, ml: 2, display: "flex", flexDirection: "column", width: "100%", boxShadow: 4 }}>
                            <Typography sx={{ ml: 2.5, marginTop: "8px", fontSize: "16px", textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                                Yearl Subscription - Yellow Market - Classic Plan
                            </Typography>
                            {underlineView()}
                            {renderDetailItemView(t("YC_YEARLY_SUBSCRIPTION"), "9000.00")}
                            {underlineView()}
                            {renderDetailItemView("SME Business Advert plan", "6000.00")}
                            {underlineView()}
                            {renderDetailItemView("Total incl Vat", "MUR 15,000.00", true)}
                            {underlineView()}
                            <CustomButton
                                placeHolder={t("YC_CHANGE_PLAN")}
                                textTransform="none"
                                background={BLUE}
                                titleColor={WHITE}
                                height={40}
                                marginTop={10}
                                marginLeft={20}
                                marginBottom={20}
                                width={"90%"}
                                borderRadius={7.2}
                                onClick={() => { }}
                                hoverColor={WHITE}
                                buttonStyle={{ backgroundColor: BLUE, fontSize: 14 }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, ml: 1, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "100%", lg: "90%", xl: "90%" } }}>
                        <Box sx={{ mt: 2, mb: 2, ml: 2, display: "flex", flexDirection: "column", width: "100%", boxShadow: 4 }}>
                            <Typography sx={{ ml: 2.5, marginTop: "8px", fontSize: "16px", textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                                {t("YC_ACCOUNT_STATUS")}
                            </Typography>
                            {underlineView()}
                            {renderAccountStatusItemView("Yellow Market Classic+:", "MUR 15,000.00 every 1 year")}
                            {underlineView()}
                            {renderAccountStatusItemView(`${t("YC_PAYMENT_METHOD")}:`, "Payment Method - Expired")}
                            {underlineView()}
                            {renderAccountStatusItemView(`${t("YC_RENEWAL_ACTIVATION_DATE")}:`, "December 4, 2022")}
                            {underlineView()}
                            <CustomButton
                                placeHolder={t("YC_ADD_PAYMENT_METHOD")}
                                textTransform="none"
                                background={BLUE}
                                titleColor={WHITE}
                                height={40}
                                marginTop={10}
                                marginLeft={20}
                                marginBottom={20}
                                width={"90%"}
                                borderRadius={7.2}
                                onClick={() => { }}
                                hoverColor={WHITE}
                                buttonStyle={{ backgroundColor: BLUE, fontSize: 14 }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, ml: 1, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "100%", lg: "90%", xl: "90%" } }}>
                        <Box sx={{ mt: 2, mb: 2, ml: 2, display: "flex", flexDirection: "column", width: "100%", boxShadow: 4 }}>
                            <Typography sx={{ ml: 2.5, marginTop: "8px", fontSize: "16px", textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                                {t("YC_ACCOUNT_ACTIONS")}
                            </Typography>
                            {underlineView()}
                            <Link sx={{
                                pl: 2, mb: 2, color: RED, textAlign: "start", width: "20%", "&:hover": {
                                    color: YELLOW,
                                    cursor: "pointer"
                                }
                            }} variant="h6" fontSize={12} onClick={() => { }}>
                                Opt-Out
                            </Link>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, ml: 1, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "100%", lg: "90%", xl: "90%" } }}>
                        <Box sx={{ mt: 2, mb: 2, ml: 2, display: "flex", flexDirection: "column", width: "100%", boxShadow: 4 }}>
                            <Typography sx={{ ml: 2.5, marginTop: "8px", fontSize: "16px", textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                                {t("YC_SITES_LIST")}
                            </Typography>
                            {underlineView()}
                            <Box sx={{ ml: 2, display: "flex", flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" }, width: { xs: "100%", sm: "100%", lg: "90%", xl: "90%" } }}>
                                <Box sx={{ display: "flex", flexDirection: "column", width: { xs: "90%", sm: "90%", lg: "50%", xl: "50%" } }}>
                                    <Typography sx={{ fontSize: "14px", textAlign: "start" }}>
                                        {t("YC_SHOP_NAME")}
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px", textAlign: "start" }}>
                                        {t("Created on December 03, 2021")}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", width: { xs: "90%", sm: "90%", lg: "50%", xl: "50%" } }}>
                                    <CustomButton
                                        placeHolder={t("YC_VISIT_DASHBOARD")}
                                        textTransform="none"
                                        background={BLUE}
                                        titleColor={WHITE}
                                        height={40}
                                        marginTop={10}
                                        marginLeft={10}
                                        marginBottom={20}
                                        width={"95%"}
                                        borderRadius={7.2}
                                        onClick={() => { }}
                                        hoverColor={WHITE}
                                        buttonStyle={{ backgroundColor: BLUE, fontSize: 14 }}
                                    />
                                    <CustomButton
                                        placeHolder={t("YC_VISIT_SITE")}
                                        textTransform="none"
                                        background={BLUE}
                                        titleColor={WHITE}
                                        height={40}
                                        marginTop={10}
                                        marginLeft={5}
                                        marginBottom={20}
                                        width={"90%"}
                                        borderRadius={7.2}
                                        onClick={() => { }}
                                        hoverColor={WHITE}
                                        buttonStyle={{ backgroundColor: BLUE, fontSize: 14 }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-around",
                    alignItems: "flex-start",
                    borderRadius: "7.2px",
                    marginLeft: "10px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    width: '80%',
                    background: "#FFFFFF",
                }}
                >
                    <Box sx={{ mt: 2, ml: 1, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "100%", lg: "90%", xl: "90%" } }}>
                        <Box sx={{ mt: 2, mb: 2, ml: 2, display: "flex", flexDirection: "column", width: "100%", boxShadow: 4 }}>
                            <Typography sx={{ ml: 2.5, marginTop: "8px", fontSize: "16px", textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                               {t("YC_BILLING_HISTORY")}
                            </Typography>
                            {underlineView()}
                            <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr style={{ padding: '8px', background: GREY }}>
                    <th className={tableHeaderStyle}>Description</th>
                    <th className={tableHeaderStyle}>Date</th>
                    <th className={tableHeaderStyle}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData.items.map((item, index) => (
                    <tr style={{ border: '1px solid #ddd', padding: '8px' }} key={index}>
                      <td className={tableRowStyle}>{item.description}</td>
                      <td className={tableRowStyle}>{item.date}</td>
                      <td className={tableRowStyle}>{item.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Typography sx={{ ml: 1.5, marginTop: "8px", fontSize: "16px", textAlign: "start", color: RED }}>
                    {t("YC_NO_FURTHER_TRANSACTION")}
                </Typography>
                {fullViewUnderline()}
                <Box sx={{width: "100%", display: "flex", justifyContent: "flex-end", mb:2}}>
                    <ListItemIcon sx={{"&:hover": { cursor: "pointer" }}} onClick={() => {}}>
                      <img src={DropDownArrow} height={30} alt={"icons"} />
                    </ListItemIcon>
                </Box>
            </Box>
                            {/* {renderDetailItemView(t("YC_YEARLY_SUBSCRIPTION"), "9000.00")}
                            {underlineView()}
                            {renderDetailItemView("SME Business Advert plan", "6000.00")}
                            {underlineView()}
                            {renderDetailItemView("Total incl Vat", "MUR 15,000.00", true)}
                            {underlineView()} */}
                            {/* <CustomButton
                                placeHolder={t("YC_CHANGE_PLAN")}
                                textTransform="none"
                                background={BLUE}
                                titleColor={WHITE}
                                height={40}
                                marginTop={10}
                                marginLeft={20}
                                marginBottom={20}
                                width={"90%"}
                                borderRadius={7.2}
                                onClick={() => { }}
                                hoverColor={WHITE}
                                buttonStyle={{ backgroundColor: BLUE, fontSize: 14 }}
                            /> */}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
export default ViewMoreBillingDetails;