import { Box, Button, FormControl, InputAdornment, InputLabel, Link, MenuItem, Modal, OutlinedInput, Select, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import HomeApi from "services/HomeAPi";
import { BLACK, BLUE, BLUE_2, GREY, LINK, RED, WHITE, YELLOW } from "utils/colors";
import Info from "../../../assets/info.png"
import { useTranslation } from "react-i18next";
import CustomTitleSelect from "components/custom-title-select/custom-title-select";
import CheckBox from "components/check-box/check-box";
import CustomButton from "components/custom-button/custom-button";
const customStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
        justifyContent: "flex-around",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginLeft: "40px",
        marginTop: "20px",
        marginBottom: "20px",
        width: '92%',
        background: "#FFFFFF",
    },
}))
function BillingInformation(props: any) {
    const { t } = useTranslation()
    const { boxView } = customStyle();
    const width = (window.innerWidth * 80) / 100;
    const [resourceCodeList, setResourceCodeList] = useState(props.resourceCodeList)
    const [inputData, setInputData] = useState({
        firstName: "",
        lastName: "",
        companyName: "",
        country: "",
        state: "",
        address: "",
        city: "",
        postalCode: "",
        brn: "",
        vatNumber: "",
        invoiceCheckbox: false,
        enterCopyEmailAddress: ""
    })
    const isResourseAvailable = (resourseCode: string) => {
        let result
        if (resourceCodeList !== null) {
            result = resourceCodeList.find((resourse: any) => resourse.includes(resourseCode));
        }
        return result === resourseCode ? true : false
    }
    // useEffect(() => {
    //     if(props.userList.length === 0){
    //         getUserList()
    //     }
    //   }, [])
    // const getUserList = async () => {
    //     let userId;
    //     const loginUserDetails = localStorage.getItem("user_Details")
    //     if (loginUserDetails){
    //     const details = JSON.parse(loginUserDetails)
    //     userId = details.id
    //     }
    //     const response = await HomeApi.getUserList(userId);
    //     if (response.status === true) {
    //         setUserList(response.data)
    //         props.setIsUserAvailable(true)
    //     }
    // }
    const renderIcon = (iconName: any) => {
        return (
            <img
                src={iconName}
                height={22}
                alt={"icon"}
            />
        );
    };
    const textFieldView = (key: string, label: string = "", value: string, widthValue: string) => {
        return (
            <Box sx={{ width: widthValue, boxShadow: 4}}>
                <FormControl
                    sx={{
                        width: "100%",
                        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
                        background: WHITE,
                    }}
                    variant="outlined"
                >
                    <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
                    <OutlinedInput
                        // endAdornment={
                        //     value?.length > 0 ? 
                        //         <InputAdornment position="end">
                        //             {renderIcon(SuccessIcon)}
                        //         </InputAdornment>
                        // }
                        label={label}
                        value={value}
                        onChange={(text) => {
                            const trimStr = text.target.value.trimStart();
                            if (key === "first_name") {
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    firstName: trimStr,
                                }));
                            } else if (key === "last_name") {
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    lastName: trimStr,
                                }));
                            } else if (key === "company_name") {
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    companyName: trimStr,
                                }));
                            } else if (key === "state") {
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    state: trimStr,
                                }));
                            } else if (key === "address") {
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    address: trimStr,
                                }));
                            } else if (key === "city") {
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    lastName: trimStr,
                                }));
                            } else if (key === "postal_code") {
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    postalCode: trimStr,
                                }));
                            } else if (key === "brn") {
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    brn: trimStr,
                                }));
                            } else if (key === "vat_number") {
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    vatNumber: trimStr,
                                }));
                            } else if (key === "copy_email") {
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    enterCopyEmailAddress: trimStr,
                                }));
                            }
                        }}
                    />
                </FormControl>
            </Box>
        );
    };
    const selectionView = (
        key: any,
        items: any,
        value: any,
        label: any,
        canSelect = true) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: "71.5%",
                    boxShadow: 4
                }}
            >
                <Select
                    sx={{ height: 50 }}
                    value={value}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={(event: any) => { }}
                >
                    {items.map((item: any) => {
                        return (
                            <MenuItem
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Box>
        )
    }
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
            justifyContent: "flex-around",
            alignItems: "flex-start",
            borderRadius: "7.2px",
            marginLeft: "20px",
            marginTop: "20px",
            marginBottom: "20px",
            width: '92%',
            background: "#FFFFFF",
         }}
        >
            <Box sx={{ ml: 2, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "80%", lg: "90%", xl: "90%" } }}>
                <Typography sx={{ paddingLeft: 2, textAlign: "start" }}>FullName</Typography>
                <Box sx={{ mt: 1, display: "flex", flexDirection: "row", width: "100%" }}>
                    {textFieldView(
                        "first_name",
                        "",
                        inputData.firstName,
                        "35%"
                    )}
                    <Box sx={{ width: 10 }} />
                    {textFieldView(
                        "last_name",
                        "",
                        inputData.lastName,
                        "35%"
                    )}
                </Box>
            </Box>
            <Box sx={{ mt: 2, ml: 2, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "80%", lg: "90%", xl: "90%" } }}>
                <Typography sx={{ paddingLeft: 2, textAlign: "start" }}>Company Name</Typography>
                {textFieldView(
                        "company_name",
                        "",
                        inputData.companyName,
                        "71.5%"
                    )}
            </Box>
            <Box sx={{ mt: 2, ml: 2, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "80%", lg: "90%", xl: "90%" } }}>
                <Typography sx={{ paddingLeft: 2, textAlign: "start" }}>Country</Typography>
                <Box sx={{ mt: 1, display: "flex", flexDirection: "row", width: "100%" }}>
                    {selectionView("country", [t("YC_BEFORE_DELIVERY"), t("YC_AFTER_DELIVERY"), t("YC_ON_DELIVERY")], inputData.country, t("YC_PAYMENT_TERMS"))}
                </Box>
            </Box>
            <Box sx={{ mt: 2, ml: 2, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "80%", lg: "90%", xl: "90%" } }}>
                <Typography sx={{ paddingLeft: 2, textAlign: "start" }}>State/Province/Destrict</Typography>
                <Box sx={{ mt: 1, display: "flex", flexDirection: "row", width: "100%" }}>
                    {textFieldView(
                        "state",
                        "",
                        inputData.state,
                        "71.5%"
                    )}
                </Box>
            </Box>
            <Box sx={{ mt: 2, ml: 2, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "80%", lg: "90%", xl: "90%" } }}>
                <Typography sx={{ paddingLeft: 2, textAlign: "start" }}>Address</Typography>
                <Box sx={{ mt: 1, display: "flex", flexDirection: "row", width: "100%" }}>
                    {textFieldView(
                        "address",
                        "",
                        inputData.address,
                        "71.5%"
                    )}
                </Box>
            </Box>
            <Box sx={{ mt: 1, ml: 2, display: "flex", flexDirection: "row", width: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "column", width: { xs: "35%", sm: "28%", lg: "32%", xl: "32%" } }}>
                    <Typography sx={{ paddingLeft: 2, textAlign: "start" }}>City</Typography>
                    <Box sx={{ mt: 1, display: "flex", flexDirection: "row", width: "100%" }}>
                        {textFieldView(
                            "city",
                            "",
                            inputData.city,
                            "100%"
                        )}
                    </Box>
                </Box>
                <Box sx={{width: 10}}/>
                <Box sx={{ display: "flex", flexDirection: "column", width: { xs: "35%", sm: "28%", lg: "32%", xl: "32%" } }}>
                    <Typography sx={{ paddingLeft: 2, textAlign: "start" }}>Postal Code</Typography>
                    <Box sx={{ mt: 1, display: "flex", flexDirection: "row", width: "100%" }}>
                        {textFieldView(
                            "postal_code",
                            "",
                            inputData.postalCode,
                            "100%"
                        )}
                    </Box>
                </Box>
            </Box>
            <Box sx={{mt: 2,  ml: 2, display: "flex", flexDirection: "row", width: {xs: "70%", sm: "58%", lg: "65%", xl: "65%"}, height: 2, background: GREY }}/> 
            <Box sx={{ mt: 1, ml: 2, display: "flex", flexDirection: "row", width: "100%" }}>
                <Box sx={{ mt: 1, display: "flex", flexDirection: "column", width: { xs: "35%", sm: "28%", lg: "32%", xl: "32%" } }}>
                    <Typography sx={{ paddingLeft: 2, textAlign: "start" }}>BRN</Typography>
                    <Box sx={{ mt: 1, display: "flex", flexDirection: "row", width: "100%" }}>
                        {textFieldView(
                            "brn",
                            "",
                            inputData.brn,
                            "100%"
                        )}
                    </Box>
                </Box>
                <Box sx={{width: 10}}/>
                <Box sx={{ mt: 1, display: "flex", flexDirection: "column", width: { xs: "35%", sm: "28%", lg: "32%", xl: "32%" } }}>
                    <Typography sx={{ paddingLeft: 2, textAlign: "start" }}>VAT Number</Typography>
                    <Box sx={{ mt: 1, display: "flex", flexDirection: "row", width: "100%" }}>
                        {textFieldView(
                            "vat_number",
                            "",
                            inputData.vatNumber,
                            "100%"
                        )}
                    </Box>
                </Box>
            </Box>
            <Box sx={{ mt: 2, ml: 2, display: "flex", flexDirection: "column", width: { xs: "100%", sm: "80%", lg: "90%", xl: "90%" } }}>
                <Typography sx={{ paddingLeft: 2, textAlign: "start" }}>Invoices</Typography>
                <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    <CheckBox
                        sx={{ color: BLACK, marginLeft: "10px", "&.Mui-checked": { color: BLACK } }}
                        value="top"
                        label={t("YC_BILLING_INFO_INVOICES")}
                        labelPlacement="end"
                    />
                </Box>
            </Box> 
            <Box sx={{ mt: 1, mb:5, ml: 2, display: "flex", flexDirection: {xs: "column", sm: "row", lg: "row", xl: "row"}, width: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "column", width: { xs: "90%", sm: "70%", lg: "80%", xl: "80%" } }}>
                    <Typography sx={{ paddingLeft: 2, textAlign: "start" }}>{t("YC_ENTER_COPY_EMAIL_ADDRESS")}</Typography>
                    <Box sx={{ mt: 1, display: "flex", flexDirection: "row", width: "100%" }}>
                        {textFieldView(
                            "copy_email",
                            "",
                            inputData.enterCopyEmailAddress,
                            "81.5%"
                        )}
                    </Box>
                </Box>
                <CustomButton
                    placeHolder={t("YC_SAVE_CHANGES")}
                    textTransform="none"
                    background={BLUE}
                    titleColor={WHITE}
                    height={50}
                    marginTop={35}
                    width={"15%"}
                    borderRadius={7.2}
                    onClick={() => {}}
                    hoverColor={WHITE}
                    buttonStyle={{ backgroundColor: BLUE, fontSize: 14 }}
                />
            </Box>          
        </Box>
    );
}
export default BillingInformation;