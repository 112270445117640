import { Box, Button, Link, Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import HomeApi from "services/HomeAPi";
import { BLACK, BLUE_2, GREEN_2, LINK, RED, WHITE, YELLOW, YELLOW_2 } from "utils/colors";
import Info from "../../../assets/info.png"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const customStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
        justifyContent: "flex-around",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginLeft: "40px",
        marginTop: "20px",
        width: '35%',
        background: "#FFFFFF",
    },
}))
function BillingHistory(props: any) {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const { boxView } = customStyle();
    const [isDismissNotice, setIsDismissNotice] = useState(false)
    const width = (window.innerWidth * 80) / 100;
    const [invoiceHistoryList, setinvoiceHistoryList] = useState([{
        year: 2023,
        historyData: [{
            id: 1,
            invoiceDate: "04/01/2023",
            invoiceNo: "ym-2016-2023",
            invoiceTotal: "MUR 900.00",
            service: "Yellow Market",
            status: "Soon",
            details: ""
        },
        {
            id: 2,
            invoiceDate: "04/11/2023",
            invoiceNo: "yp-7826-2022",
            invoiceTotal: "MUR 3,000.00",
            service: "Yellow Pages",
            status: "Paid",
            details: "More Details"
    }]
    },
    {
        year: 2022,
        historyData: [{
            id: 1,
            invoiceDate: "04/01/2022",
            invoiceNo: "ym-2016-2023",
            invoiceTotal: "MUR 900.00",
            service: "Yellow Market",
            status: "Soon",
            details: ""
        },
        {
            id: 2,
            invoiceDate: "04/11/2022",
            invoiceNo: "yp-7826-2022",
            invoiceTotal: "MUR 3,000.00",
            service: "Yellow Pages",
            status: "Paid",
            details: "More Details"
    }]
    }])
    const [resourceCodeList, setResourceCodeList] = useState(props.resourceCodeList)
    const columns = [
        { field: 'Id', headerName: '', width: 0.01     },
        { field: 'invoiceDate', headerName: 'Invoice Date', width: width / 6 },
        { field: 'invoiceNo', headerName: 'Invoice No.', width: width / 6, renderCell: (params: any) =><Button
            sx={{
            textTransform: "none",
            color: RED,
            "&:hover": {
                color: YELLOW,
            },
            }}
            variant="text"
            onClick={() => {
                navigate("/invoice")
                // navigate('/invoice', { state: { params } })
            }}
        ><Link sx={{ mx: 1, color: RED, fontSize: {xs: 12, sm: 12, lg: 14, xl: 14} }} variant="h6">
        {params.value}
        </Link></Button> },
        { field: 'invoiceTotal', headerName: 'Invoice Total', width: width / 6 },
        { field: 'service', headerName: 'Service', width: width / 6 },
        { field: 'status', headerName: 'Status', width: width / 6 },
        { field: 'details', headerName: 'Details', width: width / 6, 
            renderCell: (params: any) =><Button
            sx={{
            textTransform: "none",
            "&:hover": {
                color: YELLOW,
            },
            }}
            variant="text"
            onClick={() => {navigate("/view-more-details")}}
        >(More Details)</Button>
        }
    ];
    const isResourseAvailable = (resourseCode: string) => {
        let result
        if (resourceCodeList !== null) {
          result = resourceCodeList.find((resourse: any) => resourse.includes(resourseCode));
        }
        return result === resourseCode ? true : false
    }
    const renderTableView = (historyData: any) => {
      return(
        <div style={{ height: "auto", width: '95%', marginTop: "20px", marginLeft: "10px" }}>
                <DataGrid sx={{
                    // border: "2px solid #CCD0D4",
                    boxShadow: 4,
                    '& .MuiDataGrid-cell': {
                        // color: 'primary.main',
                        backgroundColor: WHITE,
                        color: BLACK                  
                    },
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: historyData.year === 2023 ? YELLOW_2 : GREEN_2,
                        width: "100%"
                    }
                }}
                columnHeaderHeight={40}
                showColumnVerticalBorder={false}
                    rows={historyData.historyData}
                    columns={columns}
                    hideFooter={true}
                    onRowSelectionModelChange={(row: any) => {
                        historyData.historyData.map((invoice: any) => {
                                if(row[0] === invoice.id){
                                    // props.setUserForEdit(user)
                                }
                          })
                    }}
                    // pageSize={5}
                    // rowsPerPageOptions={[5]}
                    // checkboxSelection
                />
            </div>
      )
    }
    return (
        <Box className="boxView">
           {!isDismissNotice && <Box sx={{marginLeft: "10px", width: "95%", height: 40, background: BLUE_2, display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Box sx={{height: "100%", width: "80%", display: "flex", flexDirection: "row"}}>
                    <Box sx={{height: "100%", width: 5, background: YELLOW}}/>
                    <img
                    src={Info}
                    height={20}
                    style={{ marginLeft: "15px", marginTop: "10px"}}
                    alt={"unlock icon"}
                    />
                    <Typography sx={{ ml: 1, mt: 1.5, color: RED, fontSize: 12}}>
                        {"One invoice is due for payment within the next 30 days."}
                    </Typography>
                </Box>
                <Box sx={{height: "100%", width: {xs: "40%", sm: "40%", lg: "20%", xl: "20%"}}}>
                    <Button
                        sx={{
                        mt: 0.5,
                        mr: 1,
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "#fff",
                        },
                        }}
                        variant="text"
                        onClick={() => {setIsDismissNotice(true)}}
                    >
                        <Link sx={{ mx: 1, color: BLACK, fontSize: {xs: 12, sm: 12, lg: 16, xl: 16} }} variant="h6">
                        {t("YC_DISMISS_NOTICE")}
                        </Link>
                    </Button>
                </Box>
            </Box>
           }
            {invoiceHistoryList.map((historyData: any) => {
                return(
                    renderTableView(historyData)
                )
            })}
            {/* <div style={{ height: "auto", width: '95%', marginTop: "20px", marginLeft: "10px" }}>
                <DataGrid sx={{
                    // border: "2px solid #CCD0D4",
                    boxShadow: 4,
                    '& .MuiDataGrid-cell': {
                        // color: 'primary.main',
                        backgroundColor: WHITE,
                        color: BLACK                  
                    },
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: YELLOW_2,
                        width: "100%"
                    }
                }}
                columnHeaderHeight={40}
                showColumnVerticalBorder={false}
                    rows={invoiceHistoryList}
                    columns={columns}
                    hideFooter={true}
                    onRowSelectionModelChange={(row: any) => {
                        invoiceHistoryList.map((user: any) => {
                                if(row[0] === user.id){
                                    props.setUserForEdit(user)
                                }
                          })
                    }}
                    // pageSize={5}
                    // rowsPerPageOptions={[5]}
                    // checkboxSelection
                />
            </div> */}
            {/* <div style={{ height: "auto", width: '95%', marginTop: "20px", marginLeft: "10px" }}>
                <DataGrid sx={{
                    // border: "2px solid #CCD0D4",
                    boxShadow: 4,
                    '& .MuiDataGrid-cell': {
                        // color: 'primary.main',
                        backgroundColor: WHITE,
                        color: BLACK                  
                    },
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: GREEN_2,
                        width: "100%"
                    }
                }}
                columnHeaderHeight={40}
                showColumnVerticalBorder={false}
                    rows={invoiceHistoryList}
                    columns={columns}
                    hideFooter={true}
                    onRowSelectionModelChange={(row: any) => {
                        invoiceHistoryList.map((user: any) => {
                                if(row[0] === user.id){
                                    props.setUserForEdit(user)
                                }
                          })
                    }}
                    // pageSize={5}
                    // rowsPerPageOptions={[5]}
                    // checkboxSelection
                />
            </div> */}
        </Box>
    );
}
export default BillingHistory;