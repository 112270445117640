import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import TextInputField from "components/text-field/text-field";
import React, { useEffect } from "react";
import SlideButton from "components/slide-button/slide-button";
import ReCAPTCHA from "react-google-recaptcha";
import ForgotPassword from "views/forgotpassword/forgotpassword";
import { BLACK, GREY, LINK, WHITE } from "utils/colors";
import { HEADER2 } from "utils/fonts";
// import {
//   YC_EMAIL_MOBILE,
//   YC_EMAIL_VALID,
//   YC_NO_ACCOUNT,
//   YC_PASSWORD,
//   YC_SIGN_IN,
//   YC_SIGN_UP_HERE_EX,
// } from "utils/strings";
import { useNavigate } from "react-router-dom";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";
import { validateEmail } from "utils/helper";
import { useTranslation } from "react-i18next";

const UseStyles = makeStyles(() => ({
  rightContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: WHITE,
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "100%",
    height: "100%",
    // height: 500,
    alignItems: "center",
    overflowY: "auto",
  },
  titleText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    fontWeight: 400,
    lineHeight: "48px",
    fontSize: 24,
    color: BLACK,
  },
  inputView: {
    width: "80%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  loginText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 30,
  },
}));

function SignInStepOneView(props: any) {
  const { rightContainer, titleText, inputView, loginText } = UseStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const [onFocus, setOnFocus] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [recaptchaResponse, setRecaptchaResponse] = React.useState("");
  const [isValidData, setIsValidData] = React.useState(false);
  const [sliderAction, setSliderAction] = React.useState(false);
  const [isShowRecaptcha, setIsShowRecaptcha] = React.useState(false);
  const [isRecaptchaDone, setIsRecaptchaDone] = React.useState(false);
  const [siteKey, setSiteKey] = React.useState("");

  const [showErrorMessage, setShowErrorMessage] = React.useState({
    email: false,
  });

  const { t } = useTranslation()

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const getRecatchaKey = async () => {
    const response = await HomeApi.getRecaptcha();
    if (response.status === true) {
        setSiteKey(response.data.site_key)
    }
  }

  useEffect(() => {
    getRecatchaKey()
  }, [])

  function validateEmptyData() {
    if (showErrorMessage.email || !password || !sliderAction || !isRecaptchaDone) {
      setIsValidData(false);
      return false;
    } else {
      setIsValidData(true);
      return true;
    }
  }

  useEffect(() => {
    validateEmptyData();
  }, [email, password, sliderAction, isShowRecaptcha,recaptchaResponse, isRecaptchaDone]);


  const validateEmailId = (value: string) => {
    const validEmail = validateEmail(value);
    setShowErrorMessage((prevFields) => ({
      ...prevFields,
      email: !validEmail,
    }));
    return validEmail;
  };

  const isShowError = () => {
    if (email && showErrorMessage.email) {
      return true;
    }
    return false;
  };

  const isShowErrorMessage = () => {
    if (email && showErrorMessage.email) {
      return t("YC_EMAIL_VALID");
    }
    return "";
  };

  const slideBtnAction = (isOn: boolean) => {
    console.log("IISS -- ", isOn);
    setIsRecaptchaDone(false)
    setIsShowRecaptcha(isOn);
    setSliderAction(isOn);
  };

  const handleRecaptchaChange = (token: string | null) => {
    if (token) {
      setRecaptchaResponse(token);
      setIsRecaptchaDone(true)
    }
  }


  const navigate = useNavigate();

  const submitLoginForm = (header: any) => {
    localStorage.clear();
    let loginData = { email, password };
    HomeApi.submitLoginForm(loginData, header).then(res => {
      if (res.status === 200 && !res.data.status && !res.data.error) {
        HomeApi.setAccessToken(res.data.Token)
        localStorage.setItem("user_Details", JSON.stringify(res.data.data))
        localStorage.setItem("login_user_Details", JSON.stringify(res.data.data))
        const setUpComplete = Number(res.data.data.isSetupComplete)
        if (res.data.data.role === "controller" || res.data.data.role === "Administrator"){
          if(setUpComplete === 1) {
            navigate("/incomplete-dashboard")
          } else {
            navigate("/dashboard")
          }
        } else {
          navigate("/incomplete-dashboard")
        }
      } else {
        if (res.data.error) {
          throw res.data.error[0];
        }
        throw res.data.message ? res.data.message : "Something went wrong";
      }
    }).catch(err => {

      Notification.notifyError(err);
    })
  }

  return (
    <Box className={rightContainer}>
      <Typography className={titleText}>{t("YC_SIGN_IN_YCLICK")}</Typography>
      <Box sx={{ mt: 5 }} className={inputView}>
        <TextInputField
          isError={isShowError()}
          helperText={isShowErrorMessage()}
          label={t("YC_EMAIL_MOBILE")}
          width={"100%"}
          // height={windowHeight * 0.01}
          value={email}
          onChange={(text: any) => {
            setEmail(text.target.value);
            validateEmailId(text.target.value);
            // validateEmptyData();
          }}
        />
        <Box sx={{ mt: 3 }} style={{ marginRight: 10, marginLeft: 10 }}>
          <FormControl
            sx={{ width: "100%", background: WHITE }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password" sx={{ mt: password || onFocus ? 0 : -1, background: WHITE }}>
              {t("YC_PASSWORD")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              value={password}
              label={t("YC_PASSWORD")}
              size="small"
              onChange={(text) => {
                setPassword(text.target.value);
                validateEmptyData();
              }}
              onBlur={() => {
                setOnFocus(false);
              }}
              onFocus={() => {
                setOnFocus(true);
              }}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <ForgotPassword />
        </Box>
        <SlideButton
          backgroundWidth={"75%"}
          boxWidth={"55%"}
          buttonWidth={"80%"}
          slideOnClick={slideBtnAction}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 6,
          }}
        >
          {isShowRecaptcha && (
            <ReCAPTCHA
              sitekey= {siteKey ? siteKey : "6Lct7xcqAAAAAGbna9-xTHfbV9DhWyUSH_Y9R88c"}//"6Ley9ZgjAAAAAGGGJDAAd4jb1R-d0xjyqxS0ZTe6"
              onChange={handleRecaptchaChange}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 7 }}>
          <CustomButton
            disabled={!isValidData}
            placeHolder={t("YC_SIGN_IN")}
            textTransform="none"
            background={isValidData ? BLACK : GREY}
            height="40px"
            width={"70%"}
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2,
            }}
            onClick={() => { submitLoginForm({}) }}
          />
        </Box>
        <Box className={loginText}>
          <Typography sx={{ pt: 3 }} variant="h6" fontSize={16}>
            {t("YC_NO_ACCOUNT")}
          </Typography>
          <Link
            href="/signup"
            sx={{ mx: 1, mt: 3, color: LINK }}
            variant="h6"
            fontSize={16}
          >
            {t("YC_SIGN_UP_HERE_EX")}
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

function SignInPage(props: any) {
  return (
    <Box sx={style}>{SignInStepOneView(props)}</Box>
  );
}

export default SignInPage;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "90%",
  maxWidth: 700,
  minWidth: 450,
  display: "flex",
  p: 4,
};
