import * as React from 'react';
import { makeStyles } from "@mui/styles";
import { Avatar, Box, Button, Link, SvgIcon, Tab, Tabs, Typography } from '@mui/material';
import { BLACK, BLACK_2, BLUE, GREY, WHITE } from 'utils/colors';
import { useTranslation } from 'react-i18next';
import { SUBHEADER1, SUBHEADERBOLD, SUBHEADERBOLD2 } from 'utils/fonts';
import CustomButton from 'components/custom-button/custom-button';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles(() => ({
  textTypo: {
    fontStyle: "normal",
    fontSize: 12,
    textAlign: "start"
  },
  textTypo2: {
    width: "100%",
    padding: 5,
    fontStyle: "normal",
    fontSize: 14,
    textAlign: "start",
    border: "1px solid #CCD0D4",
  },
  customerDetailsView: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  invoiceTotalView: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  textCustomerDetails: {
    width: "45%",
    height: 30,
    textAlign: "start",
    fontFamily: 'roboto',
    fontSize: 14,
    fontWeight: 500
  },
  textCustomerDetails2: { ml: 1, mb: 1, height: 30, fontSize: 14, textAlign: "start" },
  invoiceTotalText2: { ml: 1, mb: 1, height: 30, fontSize: 14, textAlign: "end" }
}));
export default function Invoice(props: any) {
  const navigate = useNavigate()
  const invoiceRef = React.useRef<any>();
  const [value, setValue] = React.useState(0);
  const { customerDetailsView, textCustomerDetails, textCustomerDetails2, textTypo, textTypo2, invoiceTotalView, invoiceTotalText2 } = useStyles();
  const { t } = useTranslation()
  const [profilePhoto, setProfilePhoto] = React.useState("")
  const [inputData, setInputData] = React.useState({
    address1: "Office No. 2, 14 Avenue Sodnac",
    address2: "Quatre Bornes, Postal code: 72256",
    mobile: "+230 425 06 37",
    fax: "+230 427 50 60",
    invoiceNumber: "ym-2016-2023",
    invoiceDate: "04/01/2023",
    businessRegistrationNumber: "C14124113",
    valueAddTaxNumber: "Exempted",
    company: "Lolotte Rental And Tours Ltd",
    contact: "M. Dominique Koborg",
    address: "Office No. 1, 14 Avenue Sodnac",
    cityPostal: "Quatre Bornes, 72256",
    paymentMethod: "Credit Card",
    subTotal: "1275.00",
    salesTax: "225.00",
    total: "1500"
  })
  const handlePrint = async () => {
    const canvas = await html2canvas(invoiceRef.current);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
  
    // Get the image dimensions
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
  
    // Calculate the width and height for the PDF
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgHeight * pdfWidth) / imgWidth;
  
    // Add the image to the PDF
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight); // Width and height specified
    // pdf.save('invoice.pdf');
    // Create a Blob and open it in a new tab
    const pdfBlob = pdf.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    
    // Open the PDF in a new tab
    window.open(pdfUrl, '_blank');
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const invoiceData = {
    id: 'INV-001',
    date: '2024-10-21',
    customer: {
      name: 'John Doe',
      address: '123 Main St, Anytown, USA'
    },
    items: [
      { itemCode: "5288", description: 'Product 1', quantity: 2, priceEach: 10.00, amount: 20.00 },
      { itemCode: "5289", description: 'Product 2', quantity: 1, priceEach: 15.00, amount: 15.00 }
    ],
    total: 35.00
  }
  const appDetail = `${t("YC_DASHBOARD_APP_DETAILS")}`
  const dummyLineView = () => {
    return (
      <Box sx={{ marginLeft: "2.5%", width: "93%", height: 1.5, background: BLACK }} />
    )
  }
  const dummyLineView2 = () => {
    return (
      <Box sx={{ mb:1, width: "100%", height: 1.1, background: BLACK }} />
    )
  }
  
  return (
    <Box>
      <Typography sx={{ mt: 1, width: "100%", color: BLACK, fontStyle: SUBHEADERBOLD, textAlign: "start" }}>
        {`${t("YC_BILLING") + " " + ">" + " " + "Invoice No. xxxx" + " " + ">" + " " + "Details"}`}
      </Typography>
      <Box sx={{ border: "1px solid #CCD0D4", mt: 1.5, borderColor: GREY, boxShadow: 2 }}>
        <Box sx={{ width: "95%", display: "flex", flexDirection: "column" }} >
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <CustomButton
              placeHolder={t("YC_BACK")}
              textTransform="none"
              background={BLUE}
              titleColor={WHITE}
              height={40}
              marginTop={35}
              marginLeft={10}
              width={"10%"}
              borderRadius={7.2}
              onClick={() => {navigate(-1)}}
              hoverColor={WHITE}
              buttonStyle={{ backgroundColor: BLUE, fontSize: 14 }}
            />
            <CustomButton
              placeHolder={t("YC_PRINT_INVOICE")}
              textTransform="none"
              background={BLUE}
              titleColor={WHITE}
              height={40}
              marginTop={35}
              width={"10%"}
              borderRadius={7.2}
              onClick={() => {handlePrint()}}
              hoverColor={WHITE}
              buttonStyle={{ backgroundColor: BLUE, fontSize: 14 }}
            />
          </Box>
          <Box ref={invoiceRef} sx={{ border: "1px solid #CCD0D4", marginLeft: "5%", width: "90%", mt: 1.5, mb:5, borderColor: GREY, boxShadow: 2, }}>
            <Box sx={{ mt: 1, marginLeft: "2.5%", width: "95%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "10px",
                marginRight: 3,
              }}>
                <Avatar variant="square" src={profilePhoto} sx={{ mt: 1, mb: 1, width: 100, height: 90 }} />
                <Typography className={textTypo} gutterBottom>{inputData.address1}</Typography>
                <Typography className={textTypo} gutterBottom>{inputData.address2}</Typography>
                <Typography className={textTypo} gutterBottom>{`${"Tel :" + " " + inputData.mobile}`}</Typography>
                <Typography className={textTypo} gutterBottom>{`${"Fax :" + " " + inputData.fax}`}</Typography>
              </Box>
              <Box sx={{
                display: "flex",
                width: { xs: "50%", sm: "50%", lg: "20%", xl: "20%" },
                flexDirection: "column",
                paddingBottom: "10px",
                marginRight: 3
              }}>
                <Typography sx={{ mt: 1, mb: 1, ml: 2, width: 100, height: 30, color: BLUE, justifySelf: "center", fontSize: 26, fontStyle: SUBHEADERBOLD, }}>{t("YC_INVOICE")}</Typography>
                <Typography className={textTypo2} gutterBottom>{`${t("YC_INVOICE") + " " + t("YC_NUMBER")}`}</Typography>
                <Typography className={textTypo2} gutterBottom>{inputData.invoiceNumber}</Typography>
                <Typography className={textTypo2} gutterBottom>{`${t("YC_INVOICE") + " " + t("YC_DATE")}`}</Typography>
                <Typography className={textTypo2} gutterBottom>{inputData.invoiceDate}</Typography>
              </Box>
            </Box>
            {dummyLineView()}
            <Box sx={{ mt: 1, marginLeft: "2.5%", width: "95%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Typography sx={{ width: "50%", textAlign: 'start' }} gutterBottom>{`${t("YC_BUSINESS_REGISTRATION_NUMBER") + " " + ":" + " " + inputData.businessRegistrationNumber}`}</Typography>
              <Typography sx={{ width: "50%", textAlign: 'start' }} gutterBottom>{`${t("YC_VALUE_ADD_TAX_NUMBER") + " " + ":" + " " + inputData.valueAddTaxNumber}`}</Typography>
            </Box>
            {dummyLineView()}
            <Box sx={{ mt: 1, marginLeft: "2.5%", width: "95%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "10px",
                marginRight: 3,
                width: "50%"
              }}>
                <Typography sx={{ fontStyle: SUBHEADERBOLD2, textAlign: "start" }} gutterBottom>{t("YC_CUSTOMER_DETAILS")}</Typography>
                <Box className={customerDetailsView}>
                  <Typography className={textCustomerDetails}>{`${t("YC_COMPANY") + " " + ":" + " "}`}</Typography>
                  <Typography className={textCustomerDetails2}>{`${" " + inputData.company}`}</Typography>
                </Box>
                <Box className={customerDetailsView}>
                  <Typography className={textCustomerDetails}>{`${t("YC_CONTACT") + " " + ":" + " "}`}</Typography>
                  <Typography className={textCustomerDetails2}>{`${" " + inputData.contact}`}</Typography>
                </Box>
                <Box className={customerDetailsView}>
                  <Typography className={textCustomerDetails}>{`${t("YC_ADDRESS") + " " + ":" + " "}`}</Typography>
                  <Typography className={textCustomerDetails2}>{`${" " + inputData.address}`}</Typography>
                </Box>
                <Box className={customerDetailsView}>
                  <Typography className={textCustomerDetails}>{`${t("YC_CITY_POSTAL") + " " + ":" + " "}`}</Typography>
                  <Typography className={textCustomerDetails2}>{`${" " + inputData.cityPostal}`}</Typography>
                </Box>
              </Box>
              <Box sx={{
                display: "flex",
                width: "50%",
                flexDirection: "row",
                paddingBottom: "10px",
                marginRight: 3,
                justifyContent: "flex-end"
              }}>
                <Typography sx={{ mb: 1, height: 30, fontStyle: SUBHEADERBOLD2, textAlign: "end" }}>{`${t("YC_PAYMENT_METHOD") + " " + ":" + " "}`}</Typography>
                <Typography sx={{ ml: 1, mb: 1, height: 30, fontSize: 14, textAlign: "end" }}>{`${" " + inputData.paymentMethod}`}</Typography>
              </Box>
            </Box>
            {dummyLineView()}
            <Box sx={{ mt: 1, marginLeft: "2.5%", width: "93%", display: "flex", flexDirection: "row", border: "1px solid black", }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr style={{ padding: '8px' }}>
                    <th style={{ padding: '8px' }}>Item Code</th>
                    <th style={{ padding: '8px' }}>Description</th>
                    <th style={{ padding: '8px' }}>Quantity</th>
                    <th style={{ padding: '8px' }}>Price Each</th>
                    <th style={{ padding: '8px' }}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData.items.map((item, index) => (
                    <tr style={{ border: '1px solid #ddd', padding: '8px' }} key={index}>
                      <td style={{ padding: '8px' }}>{item.itemCode}</td>
                      <td style={{ padding: '8px' }}>{item.description}</td>
                      <td style={{ padding: '8px' }}>{item.quantity}</td>
                      <td style={{ padding: '8px' }}>${item.priceEach.toFixed(2)}</td>
                      <td style={{ padding: '8px' }}>{item.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
            <Box sx={{ mt: 1, marginLeft: "2.5%", width: "93%", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <Box sx={{
                mt: 2,
                mb: 2,
                display: "flex",
                flexDirection: "column",
                width: {xs: "100%", sm: "100%", lg: "35%", xl: "35%"},
                justifyContent: "flex-end",
                border: "1px solid black",
                padding: 1
              }}>
                <Box className={invoiceTotalView}>
                  <Typography className={textCustomerDetails}>{`${t("YC_SUB_TOTAL") + " "+ ":"+ " " }`}</Typography>
                  <Typography className={invoiceTotalText2}>{`${ " " + inputData.subTotal}`}</Typography>
                </Box>
                {dummyLineView2()}
                <Box className={invoiceTotalView}>
                  <Typography className={textCustomerDetails}>{`${t("YC_SALES_TAX") + " "+ ":"+ " " }`}</Typography>
                  <Typography className={invoiceTotalText2}>{`${ " " + inputData.salesTax}`}</Typography>
                </Box>
                {dummyLineView2()}
                <Box className={invoiceTotalView}>
                  <Typography className={textCustomerDetails}>{`${t("YC_TOTAL") + " "+ ":"+ " " }`}</Typography>
                  <Typography className={invoiceTotalText2}>{`${ " " + inputData.total}`}</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 1, marginLeft: "2.5%", width: "93%", display: "flex", flexDirection: "row"}}>
              <Box sx={{
                mt: 2,
                mb: 2,
                display: "flex",
                flexDirection: "column",
                width: "100%",
                border: "1px solid black",
                padding: 1
              }}>
                <Box className={invoiceTotalView}>
                  <Typography className={textCustomerDetails}>{`${t("YC_USEFUL_INFORMATION")}`}</Typography>
                </Box>
                {dummyLineView2()}
                <Box className={invoiceTotalView}>
                  <Typography className={invoiceTotalText2}>{`${ " " + inputData.salesTax}`}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}