import { Box, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomButton from "components/custom-button/custom-button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BLACK, BLUE, BLUE_4, BLUE_5, GREY, RED, WHITE } from "utils/colors";
import { HEADER2 } from "utils/fonts";

function Transfer(props: any) {
    const { t } = useTranslation()
    const width = (window.innerWidth * 80) / 100;
    const [paymentType, setPaymentType] = useState(["Credit Cards", "Debit cards", "Bank transfer", "Mobile payments", "Digital wallets"])
    const [channel, setChannel] = useState(["Online checkout",
        "B2B payment platforms",
        "In-app payments",
        "Virtual terminal",
        "In-person terminal",
        "Bank transfers",
        "Remittances",
        "Recurring payments i.e. subscription"])
    const [remarks, setRemarks] = useState(["reason for transfer: Settlement", "Fund Transfer", "Purchased Goods"])
    const [transferList, setTransferList] = useState([{
        id: 1,
        title: "outward",
        type: "Bank",
        amount: "4000.00",
        accountName: "Lolotte Rental and Tours",
        bankName: "Absa (Mauritius)",
        reference: "Fund Transfer",
        added: "2022-11-25 ",
        status: "Processed"
    },
    {
        id: 2,
        title: "Inward",
        type: "Bank",
        amount: "2500.00",
        accountName: "MYP Online Marketing",
        bankName: "Maubank",
        reference: "Inv. LRT 20352",
        added: "2022-11-25 ",
        status: "Processed"
    },
    {
        id: 3,
        title: "Outward",
        type: "Bank",
        amount: "5000.00",
        accountName: "Y-Click",
        bankName: "Myt Money",
        reference: "YP Subscription Cyber Plan",
        added: "2022-11-25 ",
        status: "Processed"
    }])

    const [inputData, setInputData] = useState({
        paymentValue: "",
        channelValue: "",
        remarksValue: ""
    })

    const columns = [
        { field: 'Id', headerName: '', width: 0.01 },
        { field: 'title', headerName: 'Title', width: width / 9 - 50 },
        { field: 'type', headerName: 'Type', width: width / 9 - 50 },
        { field: 'amount', headerName: 'Amount', width: width / 9 },
        { field: 'accountName', headerName: 'Acc. Name', width: width / 9 },
        { field: 'bankName', headerName: 'Bank Name', width: width / 9 },
        { field: 'reference', headerName: 'Reference', width: width / 9 },
        { field: 'added', headerName: 'Added', width: width / 9 },
        { field: 'status', headerName: 'Status', width: width / 9 }
    ];

    const selectionView = (
        key: any,
        items: any,
        value: any,
        label: any,
        canSelect = true) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: "100%"
                }}
            >
                <Select
                    sx={{ height: 40, width: "100%", textAlign: "start" }}
                    value={value}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={(event: any) => {
                        if (key === "payment_type") {
                            setInputData((prevFields: any) => ({
                                ...prevFields,
                                paymentValue: event.target.value
                            }))
                        } else if (key === "channel") {
                            setInputData((prevFields: any) => ({
                                ...prevFields,
                                channelValue: event.target.value
                            }))
                        } else if (key === "remarks") {
                            setInputData((prevFields: any) => ({
                                ...prevFields,
                                remarksValue: event.target.value
                            }))
                        }
                    }}
                    renderValue={(selected: any) => {
                        if (selected.length === 0) {
                            if (key === "payment_type") {
                                return <>{t("YC_PAYMENT_TYPES")}</>;
                            } else if (key === "channel") {
                                return <>{t("YC_CHANNEL")}</>;
                            } else if (key === "remarks") {
                                return <>{t("YC_REMARKS")}</>;
                            }
                        }
                        return selected;
                    }}
                >
                    {items.map((item: any) => {
                        return (
                            <MenuItem
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Box>
        )
    }

    const renderTableView = (transferData: any) => {
        return (
            <div style={{ height: "auto", width: '100%' }}>
                <DataGrid sx={{
                    // border: "2px solid #CCD0D4",
                    boxShadow: 4,
                    '& .MuiDataGrid-cell': {
                        // color: 'primary.main',
                        backgroundColor: WHITE,
                        color: BLACK
                    },
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: BLUE_5,
                        width: "100%"
                    }
                }}
                    columnHeaderHeight={40}
                    showColumnVerticalBorder={false}
                    rows={transferData}
                    columns={columns}
                    hideFooter={true}
                    onRowSelectionModelChange={(row: any) => {
                        transferData.map((invoice: any) => {
                            //   if(row[0] === invoice.id){
                            //   }
                        })
                    }}
                    // pageSize={5}
                    // rowsPerPageOptions={[5]}
                    checkboxSelection
                />
            </div>
        )
    }

    return (
        <Box sx={{ m: 2, width: "95%", display: "flex", flexDirection: "column", background: GREY, boxShadow: 2 }}>
            <Box sx={{ m: 2, width: "95%", display: "flex", flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" } }}>
                <Box sx={{ mt: 2, mb: 2, ml: 2, width: { xs: "95%", sm: "95%", lg: "45%", xl: "45%" }, display: "flex", flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" }, background: WHITE, boxShadow: 2 }}>
                    <Box sx={{ mt: 1.5, ml: 1, mr: 1, width: { xs: "95%", sm: "95%", lg: "60%", xl: "60%" } }}>
                        <CustomButton
                            placeHolder={t("YC_EFFECT_TRANSFER")}
                            textTransform="none"
                            background={BLUE}
                            height="40px"
                            width={"100%"}
                            borderRadius="5px"
                            buttonStyle={{
                                HEADER2
                            }}
                            onClick={() => { }}
                        />
                    </Box>
                    <Box sx={{ mt: 1.5, ml: 1, mr: 1, width: { xs: "95%", sm: "95%", lg: "60%", xl: "60%" } }}>
                        <CustomButton
                            placeHolder={t("YC_TRANSFER_REQUEST")}
                            textTransform="none"
                            background={BLUE_4}
                            height="40px"
                            width={"100%"}
                            borderRadius="5px"
                            buttonStyle={{
                                HEADER2
                            }}
                            titleColor={BLACK}
                            onClick={() => { }}
                        />
                    </Box>
                    <Box sx={{ mt: 1.5, ml: 1, mr: 1, mb:1, width: { xs: "95%", sm: "95%", lg: "60%", xl: "60%" } }}>
                        <CustomButton
                            placeHolder={t("YC_CANCEL_TRANSFER")}
                            textTransform="none"
                            background={RED}
                            height="40px"
                            width={"100%"}
                            borderRadius="5px"
                            buttonStyle={{
                                HEADER2
                            }}
                            onClick={() => { }}
                        />
                    </Box>
                </Box>
                <Box sx={{ m: 2, width: { xs: "95%", sm: "95%", lg: "55%", xl: "55%" }, display: "flex", flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" }, background: WHITE }}>
                    <Box sx={{ ml: 1, width: { xs: "95%", sm: "95%", lg: "60%", xl: "60%" }, height: "40px", marginTop: "10px", maxWidth: { xs: "95%", sm: "95%", lg: "200px", xl: "200px" } }}>
                        {selectionView("payment_type", paymentType, inputData.paymentValue, t("YC_PAYMENT_TYPES"))}
                    </Box>
                    <Box sx={{ ml: 1, width: { xs: "95%", sm: "95%", lg: "60%", xl: "60%" }, height: "40px", marginTop: "10px", maxWidth: { xs: "95%", sm: "95%", lg: "200px", xl: "200px" } }}>
                        {selectionView("channel", channel, inputData.channelValue, t("YC_CHANNEL"))}
                    </Box>
                    <Box sx={{ ml: 1, mr: 1, mb: 2, width: { xs: "95%", sm: "95%", lg: "60%", xl: "60%" }, height: "40px", marginTop: "10px", maxWidth: { xs: "95%", sm: "95%", lg: "200px", xl: "200px" } }}>
                        {selectionView("remarks", remarks, inputData.remarksValue, t("YC_REMARKS"))}
                    </Box>
                </Box>
            </Box>
            <Box sx={{ marginLeft: "3%", mb: 4, width: "92%", display: "flex", flexDirection: "column", background: GREY, boxShadow: 2 }}>
                {renderTableView(transferList)}
            </Box>
        </Box>
    );
}

export default Transfer;
