import { Box, Button, Link, Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import HomeApi from "services/HomeAPi";
import { BLACK, BLUE, BLUE_2, GREEN, GREY, GREY_6, LINK, RED, WHITE, YELLOW } from "utils/colors";
import CardImage from "../../../assets/cardImage.png"
import Info from "../../../assets/info.png"
import { useTranslation } from "react-i18next";
import CustomButton from "components/custom-button/custom-button";
import { SUBHEADERBOLD, SUBHEADERBOLD2 } from "utils/fonts";
const customStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
        justifyContent: "flex-around",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginLeft: "40px",
        marginTop: "20px",
        width: '35%',
        background: "#FFFFFF",
    },
}))
function BillingSettings(props: any) {
    const { t } = useTranslation()
    const { boxView } = customStyle();
    const width = (window.innerWidth * 80) / 100;
    const [isDismissNotice, setIsDismissNotice] = useState(false)
    const [invoiceList, setInvoiceList] = useState([{
        id: 1,
        invoiceDate: "04/01/2023",
        invoiceNo: "ym-2016-2023",
        invoiceTotal: "MUR 900.00",
        service: "Yellow Market",
        status: "Soon",
        details: ""
    },
    {
        id: 2,
        invoiceDate: "04/11/2023",
        invoiceNo: "yp-7826-2022",
        invoiceTotal: "MUR 3,000.00",
        service: "Yellow Pages",
        status: "Paid",
        details: "More Details"
    }])
    const [resourceCodeList, setResourceCodeList] = useState(props.resourceCodeList)
    const columns = [
        { field: 'Id', headerName: '', width: 0.01 },
        { field: 'invoiceDate', headerName: 'Invoice Date', width: width / 6 },
        {
            field: 'invoiceNo', headerName: 'Invoice No.', width: width / 6, renderCell: (params: any) => <Button
                sx={{
                    textTransform: "none",
                    color: RED,
                    // "&:hover": {
                    //     color: YELLOW,
                    // },
                }}
                variant="text"
                onClick={() => { }}
            >{params.value}</Button>
        },
        { field: 'invoiceTotal', headerName: 'Invoice Total', width: width / 6 },
        { field: 'service', headerName: 'Service', width: width / 6 },
        { field: 'status', headerName: 'Status', width: width / 6 },
        {
            field: 'details', headerName: 'Details', width: width / 6,
            renderCell: (params: any) => <Button
                sx={{
                    textTransform: "none",
                    "&:hover": {
                        color: YELLOW,
                    },
                }}
                variant="text"
                onClick={() => { }}
            >(More Details)</Button>
        }
    ];
    const isResourseAvailable = (resourseCode: string) => {
        let result
        if (resourceCodeList !== null) {
            result = resourceCodeList.find((resourse: any) => resourse.includes(resourseCode));
        }
        return result === resourseCode ? true : false
    }
    const renderOptionView = (title: string, textValue: string, buttonName: string) => {
        return(
            <Box sx={{mt:2, display: "flex", flexDirection: "column", width: {xs: "100%", sm: "100%", lg: "30%", xl: "30%"}, justifyContent: "center" }}>
                    <Box sx={{mt:2, marginLeft: "5%", display: "flex", flexDirection: "column", width: "90%", justifyContent: "center" }}>
                        <img
                            src={CardImage}
                            height={60}
                            width={60}
                            style={{ marginLeft: "40%"}}
                            // alt={"unlock icon"}
                        />
                        <Typography sx={{mt:2, width:"95%", marginLeft: "2.5%", fontSize: 12, fontStyle: SUBHEADERBOLD2 }}>
                        {title}
                        </Typography>
                        <Typography sx={{mt:2, width:"95%", marginLeft: "2.5%", fontSize: 12 }}>
                        {textValue}
                        </Typography>
                    </Box>
                    <Box sx={{mt:2, marginLeft: "5%", display: "flex", flexDirection: "column", width: "90%", justifyContent: "center", background: GREY_6 }}>
                    <Box sx={{height: 10}}/>
                    <Typography sx={{mt:2, width:"95%", marginLeft: "2.5%", fontSize: 12 }}>
                       Image so of Credit Card Accepted
                    </Typography>
                    <CustomButton
                        placeHolder={buttonName}
                        textTransform="none"
                        background={BLUE}
                        titleColor={WHITE}
                        height={40}
                        marginTop={"25%"}
                        marginLeft={20}
                        marginBottom={20}
                        width={"90%"}
                        borderRadius={7.2}
                        onClick={() => {console.log("setIsCreditCardButtonClicked")
                            props.setIsCreditCardButtonClicked(true)}}
                        hoverColor={WHITE}
                        buttonStyle={{ backgroundColor: GREEN, fontSize: 14 }}
                    />
                    </Box>
            </Box>
        )
    }
    return (
        <Box className="boxView">
            {!isDismissNotice && <Box sx={{ marginLeft: "10px", width: "95%", height: 40, background: BLUE_2, display: "flex", flexDirection: {xs: "column", sm: "column", lg: "row", xl: "row"}, justifyContent: "space-between" }}>
                <Box sx={{ height: "100%", width: "80%", display: "flex", flexDirection: "row" }}>
                    <Box sx={{ height: "100%", width: 5, background: YELLOW }} />
                    <img
                        src={Info}
                        height={20}
                        style={{ marginLeft: "15px", marginTop: "10px" }}
                        alt={"unlock icon"}
                    />
                    <Typography sx={{ ml: 1, mt: 1.5, color: RED, fontSize: 12 }}>
                        {t("YC_DO_NOT_VERIFIED_PAYMENT")}
                    </Typography>
                </Box>
                <Box sx={{ height: "100%", width: {xs: "40%", sm: "30%", lg: "20%", xl: "20%"} }}>
                    <Button
                        sx={{
                            mt: 0.5,
                            mr: 1,
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "#fff",
                            },
                        }}
                        variant="text"
                        onClick={() => {setIsDismissNotice(true)}}
                    >
                        <Link sx={{ mx: 1, color: BLACK }} variant="h6" fontSize={16}>
                            {t("YC_DISMISS_NOTICE")}
                        </Link>
                    </Button>
                </Box>
            </Box>
            }
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
                justifyContent: "flex-around",
                alignItems: "flex-start",
                borderRadius: "7.2px",
                marginLeft: "10px",
                marginTop: "20px",
                marginBottom: "20px",
                width: '95%',
                background: "#FFFFFF",
            }}
            >
                <Box sx={{ mt: 2, ml: 2, display: "flex", flexDirection: "column", width: "100%" }}>
                    <Typography sx={{ ml: 4, fontStyle: SUBHEADERBOLD }}>{t("YC_ADD_VERIFIED_PAYMENT_METHOD")}</Typography>
                </Box>
                <Box sx={{mt:2, width: "60%", height: 1.5, marginLeft: "20%", background: GREY }} />
                <Box sx={{mt:2, ml: 2, display: "flex", flexDirection: {xs: "column", sm: "column", lg: "row", xl: "row"}, width: "95%", justifyContent: "space-between"}}>
                    {renderOptionView(t("YC_ADD_VERIFIED_CREDIT_CARD"), t("YC_OPTIN_RENEW_PURCHASE_TEXT"), t("YC_ADD_CREDIT_DEBIT_CARD"))}
                    {renderOptionView(t("YC_ADD_POP_FOR_BANK_TRANSFER"), t("YC_POP_PAYMENT_TEXT"), t("YC_ADD_CREDIT_DEBIT_CARD"))}
                    {renderOptionView(t("YC_YCLICK_WALLET"), `${t("YC_EASY_CHEAPER")+ " " + "......."}`, t("YC_ADD_CREDIT_DEBIT_CARD"))}
                </Box>
            </Box>
        </Box>
    )
}
export default BillingSettings;