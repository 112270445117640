import React, { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Button,
    Select,
    MenuItem,
    InputLabel,
    TextField,
    Typography,
    LinearProgress,
    Card,
    CardMedia,
    IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BLACK, BLUE_4, RED, WHITE } from 'utils/colors';
import { useTranslation } from 'react-i18next';
import StepFooter from "../dashboard-reusables/step-footer";
import { SUBHEADERBOLD2 } from "utils/fonts";
import ListIcon from "../../../assets/listIcon.png"
import GridIcon from "../../../assets/gridIcon.png"
import HomeApi from "services/HomeAPi";

const useStyles = makeStyles(() => ({
    appText: {
        fontFamily: 'Roboto',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "23.3397px",
        lineHeight: "50px",
        color: "#FFFFFF",
        textAlign: "start",
    }
}));

const MyFiles = () => {
    const { t } = useTranslation()
    const { appText } = useStyles();
    const [fileType, setFileType] = useState("")
    const [fileTypeList, setFileTypeList] = useState(["jpg", "pdf", "png", "all"])
    const [date, setDate] = useState("")
    const [dateList, setDateList] = useState(["Today", "This month", "Year"])
    const [purpose, setPurpose] = useState("")
    const [purposeList, setPurposeList] = useState(["Purpose"])
    const [shareWith, setShareWith] = useState("")
    const [shareWithList, setShareWithList] = useState([])
    const [shareWithNameList, setShareWithNameList] = useState([])

    const getUserOrganisationNotify = async () => {
        const response = await HomeApi.getUserOrganisationNotify();
        if (response.status === true) {
            setShareWithList(response.data)
            let tempArray: any = [];
            response.data.map((data: any) => {
             tempArray.push(data.name)
            });
            setShareWithNameList(tempArray)
        }
    }

    useEffect(() => {
        getUserOrganisationNotify()
    }, [])

    const selectionView = (
        key: any,
        items: any,
        value: any,
        label: any,
        canSelect = true) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: "100%",
                    minWidth: "150px",
                    mb: 1
                }}
            >
                <Select
                    sx={{ height: 40, width: "100%", textAlign: "start" }}
                    value={value}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={(event: any) => {
                        if (key === "file_type") {
                            // setInputData((prevFields: any) => ({
                            //     ...prevFields,
                            //     paymentValue: event.target.value
                            // }))
                        } else if (key === "date") {
                        } else if (key === "purpose") {
                        } else if (key === "share_with") {
                            setShareWith(event.target.value)
                        }
                    }}
                    renderValue={(selected: any) => {
                        if (selected.length === 0) {
                            if (key === "file_type") {
                                return <>{t("YC_ALL_FILE_TYPES")}</>;
                            } else if (key === "date") {
                                return <>{t("YC_ALL_DATES")}</>;
                            } else if (key === "purpose") {
                                return <>{t("YC_PURPOSE")}</>;
                            } else if (key === "share_with") {
                                return <>{t("YC_SHARE_WITH")}</>;
                            }
                        }
                        return selected;
                    }}
                >
                    {items.map((item: any) => {
                        return (
                            <MenuItem
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Box>
        )
    }

    const renderIcon = (iconName: any) => {
        return (
            <img
              // style={{opacity: 0.4}}
              src={iconName}
              height={35}
              alt={"icon"}
            />
        );
      };

    return (
        <Box>
            <Box sx={{
                minHeight: "50px",
                borderRadius: "0px",
                backgroundColor: "#000000",
                marginTop: "16px",
            }}
            >
                <Typography sx={{ ml: 2.5 }} className={appText}>
                    {t("MY_FILES")}
                </Typography>
            </Box>
            <Box sx={{
                borderRadius: "0px",
                marginTop: "15px",
                textAlign: "start",
            }}
            >
                <Typography sx={{ ml: 2.5 }}>
                    {t("YC_FILES_LIBRARY_TEXT")}
                </Typography>
            </Box>
            <hr
                style={{
                    color: BLACK,
                    backgroundColor: BLACK,
                    width: "100%",
                    marginTop: 10,
                    // marginLeft: "2.5%",
                    height: 2
                }}
            />
            <Box sx={{width: "100%", display: "flex", flexDirection: {xs: "column", sm: "column", lg: "row", xl: "row"}, justifyContent: "space-between"}}>
                <Typography sx={{mt:1, ml:2, width: "50%", fontStyle: SUBHEADERBOLD2, textAlign: "start" }}>
                    {t("YC_FILES_LIBRARY")}
                </Typography>
                <Box sx={{mt:1, width: {xs: "100%" , sm: "100%", lg: "50%", xl: "50%"}, display: "flex", justifyContent: "flex-end"}}>
                    <Grid item>
                        <Button variant="contained" color="primary">
                            {t("YC_UPLOAD")}
                        </Button>
                        <Button variant="outlined" sx={{ marginLeft: 1, background: BLUE_4, color: BLACK }}>
                            {t("YC_SHARE")}
                        </Button>
                        <Button variant="outlined" sx={{ marginLeft: 1, background: RED, color: WHITE,  '&:hover': {color: BLACK}, }}>
                            {t("YC_REMOVE")}
                        </Button>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{ padding: 3 }}>
                {/* Toolbar */}
                <Box sx={{width: "100%", display: "flex", flexDirection: {xs: "column", sm: "column", lg: "row", xl: "row"}, border: "2px solid #CCD0D4"}}>
                <Box sx={{mt:0.5, width: "80px", display: "flex", flexDirection: "row"}}>
                    <IconButton
                        sx={{width: 40, height: 40, mt:0.5}}
                        aria-label="delete"
                        onClick={() => {}}
                    >
                        {renderIcon(ListIcon)}
                    </IconButton>
                    {/* </Grid> */}
                    {/* <Grid item sx={{marginLeft: "-50px"}}> */}
                    <IconButton
                    sx={{width: 40, height: 40, mt: 0.5}}
                        aria-label="delete"
                        onClick={() => {}}
                    >
                        {renderIcon(GridIcon)}
                    </IconButton>
                    </Box>
                <Grid container spacing={1} alignItems="center" width={"100%"} mt={0.5} ml={0.5}>
                    {/* Dropdown Filters */}
                    <Grid item>
                        {selectionView("date", dateList, date, t("YC_ALL_DATES"))}
                    </Grid>
                    <Grid item>
                        {/* <Select defaultValue="all" sx={{mb:1, minWidth: 120, height: 40 }}>
                            <MenuItem value="all">All File Types</MenuItem>
                            <MenuItem value="image">Images</MenuItem>
                            <MenuItem value="pdf">PDFs</MenuItem>
                        </Select> */}
                        {selectionView("file_type", fileTypeList, fileType, t("YC_ALL_FILE_TYPES"))}
                    </Grid>
                    <Grid item>
                        {selectionView("date", dateList, date, t("YC_ALL_DATES"))}
                    </Grid>
                    <Grid item>
                       {selectionView("purpose", purposeList, purpose, t("YC_PURPOSE"))}
                    </Grid>
                    <Grid item>
                       {selectionView("share_with", shareWithNameList, shareWith, t("YC_SHARE_WITH"))}
                    </Grid>
                    {/* Search Bar */}
                    <Box sx={{display: "flex", flexDirection: "row"}}>
                    <Typography sx={{ml:1, mt:1, width: "50PX", textAlign: "start" }}>
                    {t("YC_SEARCH")}:
                </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Search"
                            sx={{width: "200px"}}
                        />
                    </Box>
                    {/* Action Buttons */}
                </Grid>
                </Box>
                {/* File Preview Section */}
                <Box sx={{ marginTop: 4 }}>
                    <Card sx={{ width: 120, height: 120, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CardMedia
                            component="img"
                            alt="File Preview"
                            image="/placeholder.png" // Replace with dynamic image
                            sx={{ width: 60, height: 60 }}
                        />
                    </Card>
                    <Typography gutterBottom>
                        Showing 1 of 1 media items
                    </Typography>
                </Box>

                {/* Usage Bar */}
                <Box sx={{ marginTop: 4, display: "flex", flexDirection: "row", width: {xs: "100%" , sm: "100%", lg: "50%", xl: "50%"} }}>
                    <Box sx={{ marginTop: 4, display: "flex", flexDirection: "column", width: "60%", justifyContent: "space-between" }}>
                        <LinearProgress color="error" variant="determinate" value={80} sx={{width: "90%", height: 10, borderRadius: 5, backgroundColor: "#f5f5f5" }} />
                        <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                            Usage: 80%
                        </Typography>
                    </Box>
                    <Button variant="contained" color="secondary" sx={{ marginTop: 2, width: 100, height: 40 }}>
                        Clean Up
                    </Button>
                </Box>

                {/* Footer */}
                <StepFooter
                text={"Do you need assistance? "}
                link={"Click here to access Tutorials"}
            />
            </Box>
        </Box>
    );
};

export default MyFiles;
